/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect, useState } from 'react';
import toggleDollarsImage from '../../assets/Icons/toggle-left-nospace.png';
import toggleKwhImage from '../../assets/Icons/toggle-right-nospace.png';
import { useNavigate } from "react-router-dom";
import { Box, Button } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ChartWeekPrevious from "./ChartWeekPrevious";
import useImage from '../../assets/Icons/energy-used.png';
import { Link } from 'react-router-dom';
import ChartMonthPrevious from "./ChartMonthPrevious";
import ChartWeekLastYear from "./ChartWeekLastYear";
import CharMonthLastYear from "./ChartMonthLastYear";
import { ePeriod, getPeriodData, getYearPeriodData } from "../../components/DashboardBase/DashboardBase";
import { getStorageJSON } from "../../utils";
import { eStorage } from "../../services/StorageService";
import ChartLastYear from "./ChartLastYear";

function ChartContainer(props) {
    const [user] = useState(getStorageJSON(eStorage.user));
    const [periodData, setPeriodData] = useState({});
    const [period, setPeriod] = useState('');
    const [viewType, setViewType] = useState('dollars');
    const [currentView, setCurrentView] = useState({});
    const navigate = useNavigate();
    const [value, setValue] = useState(ePeriod.lastWeekVSPrevious);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (props.setPeriodData) {
            props.setPeriodData(periodData);
        }
        if (props.setCurrentView) {
            console.log(currentView);
            props.setCurrentView(currentView);
        }
    }, [periodData]);

    const getData = () => {
        Promise.all([
            getPeriodData(ePeriod.lastWeekVSPrevious, viewType),
            getPeriodData(ePeriod.lastMonthVSPrevious, viewType),
            getPeriodData(ePeriod.lastWeekVSLastYear, viewType),
            getPeriodData(ePeriod.lastMonthVSLastYear, viewType),
            getYearPeriodData(viewType)
        ]).then((values) => {
            if (values[1]) {
                setPeriod(ePeriod.lastWeekVSPrevious);
                setCurrentView(values[0]);
            }
            console.log(values[4]);

            setPeriodData({
                lastWeekVSPrevious: values[0],
                lastMonthVSPrevious: values[1],
                lastWeekVSLastYear: values[2],
                lastMonthVSLastYear: values[3],
                lastYear: values[4]
            });
            props.setPeriodData({
                lastWeekVSPrevious: values[0],
                lastMonthVSPrevious: values[1],
                lastWeekVSLastYear: values[2],
                lastMonthVSLastYear: values[3],
                lastYear: values[4]
            });
        }).catch(error => console.error)
            .finally()
    }

    const handleTypeChange = (viewType) => {
        setViewType(viewType);
        getData();
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.setPeriodName(getTabName(newValue));
        handlePeriodSelect(newValue);
        if (props.setPeriodValue)
            props.setPeriodValue(newValue);
    };

    const handlePeriodSelect = (value) => {
        setPeriod(value);
        let period = {};
        if (value === ePeriod.lastWeekVSPrevious)
            period = periodData.lastWeekVSPrevious;
        else if (value === ePeriod.lastMonthVSPrevious)
            period = periodData.lastMonthVSPrevious;
        else if (value === ePeriod.lastWeekVSLastYear)
            period = periodData.lastWeekVSLastYear;
        else if (value === ePeriod.lastMonthVSLastYear)
            period = periodData.lastMonthVSLastYear;
        else if (value === ePeriod.lastYear)
            period = periodData.lastYear;

        props.setCurrentView(period);
        setCurrentView(period);
    }

    const getTabName = (value) => {
        let title = 'Weeks';
        if (!value) return title;
        switch (value) {
            case ePeriod.lastWeekVSPrevious: title = "Weeks"; break;
            case ePeriod.lastMonthVSPrevious: title = "Months"; break;
            case ePeriod.lastWeekVSLastYear: title = "Week year"; break;
            case ePeriod.lastMonthVSLastYear: title = "Month year"; break;
            case ePeriod.lastYear: title = "Last Year"; break;
            default: title = 'Weeks';
        }
        return title;
    }

    return (
        <div className="container-card card-content pb-3">
            <h2 className="text-start title-section mb-0">CONSUMPTION</h2>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Weeks" value={ePeriod.lastWeekVSPrevious} />
                        <Tab label="Months" value={ePeriod.lastMonthVSPrevious} />
                        <Tab label="week-year" value={ePeriod.lastWeekVSLastYear} />
                        <Tab label="month-year" value={ePeriod.lastMonthVSLastYear} />
                        <Tab label="last-year" value={ePeriod.lastYear} />
                    </TabList>
                </Box>
                <div className="row mt-3 mb-2">
                    <div className="col-6 text-start">
                        <span className="small-legend">$/kWh</span>
                        {viewType === 'dollars' && <img src={toggleDollarsImage} className="icon-switch" alt="money" onClick={() => { handleTypeChange('kwh') }} />}
                        {viewType === 'kwh' && <img src={toggleKwhImage} className="icon-switch" alt="electricity" onClick={() => { handleTypeChange('dollars') }} />}
                        <p className="small-legend">
                            <strong> {Math.abs(Math.round(currentView.temperature_difference))}°F </strong> {currentView.temperature_difference > 0 ? 'HOTTER' : 'COOLER'}
                        </p>
                    </div>
                    <div className="col-6">
                        <h4 className="label-blue-s"><img className="icon-savings-s" alt="icon-savings" src={useImage} />
                            {value === ePeriod.lastWeekVSPrevious || value == ePeriod.lastWeekVSLastYear ?
                                Math.abs(Math.round(currentView.lastWeek_total)) : Math.abs(Math.round(currentView.lastMonth_total))
                            }
                            &nbsp;kWh
                        </h4>
                        <p className="large-legend-md mt-1">
                            {period !== ePeriod.lastYear &&
                                <strong>{value === ePeriod.lastWeekVSPrevious || value == ePeriod.lastWeekVSLastYear ?
                                    Math.abs(Math.round(((currentView.lastWeek_total - currentView.previousWeek_total) / currentView.lastWeek_total) * 100)) :
                                    value === ePeriod.lastMonthVSLastYear ?
                                        Math.abs(Math.round(((currentView.lastMonth_total - currentView.previousYearMonth_total) / currentView.lastMonth_total) * 100)) :
                                        Math.abs(Math.round(((currentView.lastMonth_total - currentView.previousMonth_total) / currentView.lastMonth_total) * 100))
                                }%
                                    {value === ePeriod.lastWeekVSPrevious || value == ePeriod.lastWeekVSLastYear ?
                                        (currentView.lastWeek_total > currentView.previousWeek_total ? '+' : '-') :
                                        value === ePeriod.lastMonthVSLastYear ?
                                            (currentView.lastMonth_total > currentView.previousYearMonth_total ? '+' : '-') :
                                            (currentView.lastMonth_total > currentView.previousMonth_total ? '+' : '-')
                                    }
                                </strong>
                            }
                            {period !== ePeriod.lastYear &&
                                <span className="large-legend-sm">
                                    {props.periodName ? props.periodName : 'VS PERIOD'}
                                </span>
                            }
                        </p>
                    </div>
                </div>
                <TabPanel className="p-0 m-0" value={ePeriod.lastWeekVSPrevious}><ChartWeekPrevious periodData={periodData.lastWeekVSPrevious} /></TabPanel>
                <TabPanel className="p-0 m-0" value={ePeriod.lastMonthVSPrevious}><ChartMonthPrevious periodData={periodData.lastMonthVSPrevious} /></TabPanel>
                <TabPanel className="p-0 m-0" value={ePeriod.lastWeekVSLastYear}><ChartWeekLastYear periodData={periodData.lastWeekVSLastYear} /></TabPanel>
                <TabPanel className="p-0 m-0" value={ePeriod.lastMonthVSLastYear}><CharMonthLastYear periodData={periodData.lastMonthVSLastYear} /></TabPanel>
                <TabPanel className="p-0 m-0" value={ePeriod.lastYear}><ChartLastYear periodData={periodData.lastYear} /></TabPanel>
            </TabContext>
        </div>
    )
}

export default ChartContainer;