
import { orchestratorApiInstance, panelApiInstance } from './../../api';

export const postLogin = (model) => {
    const response = orchestratorApiInstance.post('auth/loginControlPanel', model)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const sendResetPassword = async (model) => {
    try {
        const response = await orchestratorApiInstance.post('auth/reset_password', model);
        if (response.status === 200) {
            return response.data;
        }
        return 'serverError';

    } catch (error) {
        return new Error(error.message);
    }
}

export const updateLanguageData = (customerId, language) => {
    const response = panelApiInstance.post(`customer/updatePreferredLang?customerId=${customerId}&lang=${language}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getProfile = (customerId) => {
    const response = panelApiInstance.get(`/customer/profile/${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}