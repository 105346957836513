import imageLoading  from "../../assets/loading.gif";

function Loading() {
  
  return (
    <div className="spinner">
      <img src={imageLoading} alt="loading" />
    </div>
  )
}

export default Loading;