export const eStorage = {
    bearer: 'bearer',
    isSession: 'isSession',
    session: 'session',
    user: 'user',
    ip: 'ip'
}

export const saveStorage = (key, value) => {
    localStorage.setItem(key, value);
}

export const saveStorageJSON = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export const saveRangeStorage = (values) => {
    values.forEach(function (item) {
        localStorage.setItem(item.key, item.value);
    });
}

export const getStorage = (key) => {
    return localStorage.getItem(key);
}

export const getStorageJSON = (key) => {
    return JSON.parse(localStorage.getItem(key));
}

export const clearStorage = () => {
    localStorage.clear();
}

export const deleteStorage = (key) => {
    localStorage.removeItem(key);
}

export const saveLoginStorage = (data) => {
    /* data.userInfo.agreementStatus = data.agreement_status;
    data.userInfo.customerId = data.customerId;
    data.address.serviceArea = data.serviceArea;
   let plan = {
        rep: data.rep,
        repName: data.repName,
        current: data.preferences?.selectedValues?.currentPlan
    };

    let fingerprint = data.preferences;
    fingerprint.level = data.preferences_level;*/

    let items = [
        { key: eStorage.isSession, value: true },
        { key: eStorage.bearer, value: data.bearer },
        { key: eStorage.language, value: data.preferredLang },
        //{ key: eStorage.user, value: JSON.stringify(data.userInfo) },
        //{ key: eStorage.address, value: JSON.stringify(data.address) },
        { key: eStorage.requestId, value: data.requestId },
        // { key: eStorage.plan, value: JSON.stringify(plan) },
        //{ key: eStorage.fingerprint, value: JSON.stringify(fingerprint) },
    ];

    saveRangeStorage(items);
}



export const saveRegisterStorage = (data, model) => {

    let userInfo = {
        customerId: data.customerId,
        email: model.email,
        name: model.name,
        lastName: model.lastname,
        type: data.userType
    }

    let items = [
        { key: eStorage.isSession, value: true },
        { key: eStorage.bearer, value: data.bearer },
        { key: eStorage.language, value: model.preferredLang },
        { key: eStorage.user, value: JSON.stringify(userInfo) },
        { key: eStorage.requestId, value: data.requestId }
    ];

    saveRangeStorage(items);
}

export const addPropertyStorage = (key, property, value) => {
    let current = JSON.parse(localStorage.getItem(key));
    current[property] = value;
    localStorage.setItem(key, JSON.stringify(current));
}
