import { PowerBIEmbed } from "powerbi-client-react";
import * as pbi from "powerbi-client";

const PowerBiReport = (props) => {
  const models = pbi.models;
  const reportID = '7355ae69-02dc-47f1-ba37-a5b7b2309802';
  const embedURL = 'https://app.powerbi.com/reportEmbed?reportId=7355ae69-02dc-47f1-ba37-a5b7b2309802&groupId=11540ada-bbb1-4499-ac2f-323ea1039754&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwiZGlzYWJsZUFuZ3VsYXJKU0Jvb3RzdHJhcFJlcG9ydEVtYmVkIjp0cnVlfX0%3d';  
  const powerBiToken = props.token.token;
  return (
    <PowerBIEmbed
    embedConfig={{
      type: "report",
      id: reportID,
      embedUrl: embedURL,
      accessToken: powerBiToken,
      tokenType: models.TokenType.Aad,
    }}
    cssClassName = { "report-powerbi" }
  />
  );
};
export default PowerBiReport;