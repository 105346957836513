/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from 'react';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import ChartContainer from './ChartContainer';
import { getUserData, getCountsPanelData, getPowerBIToken } from '../../components/DashboardBase/DashboardBase';
import ChartGoal from './ChartGoal';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { pink } from '@mui/material/colors';
import { DataGrid } from '@mui/x-data-grid';
import PowerBiReport from './PowerBI';

function NewHome(props) {
    const defaultProps = {
        center: {
            lat: 29.77321498327885,
            lng: -95.36767308018734
        },
        zoom: 10
    };
    const AnyReactComponent = ({ text }) => <div><LocationOnIcon sx={{ color: pink[500] }} /></div>;
    const [rows, setRows] = useState([]);
    const [currentView, setCurrentView] = useState({});
    const [periodName, setPeriodName] = useState('');
    const [periodData, setPeriodData] = useState({});
    const [panelCounts, setPanelCounts] = useState({});
    const [token, setToken] = useState({});

    const columnsDg = [
        { field: "name", headerName: "Name" },
        { field: "address", headerName: "Address" },
        { field: "agreement", headerName: "Agreement" },
        { field: "kWhYr", headerName: "KWh/yr" },
        { field: "sqft", headerName: "Sqft" },
        { field: "kWhSqft", headerName: "KWh/sqft" },
        { field: "carbonFormat", headerName: "Standard" },
        { field: 'agreementStatusFormat', headerName: 'Complete' },
        { field: 'challenges', headerName: 'Suscribed' },
        { field: 'challengesCompleted', headerName: 'Completed' },
        { field: 'ranking', headerName: 'Ranking' },
        { field: 'energyGoal', headerName: 'Energy' },
        { field: 'costGoal', headerName: 'Cost' },
        { field: 'carbonGoal', headerName: 'Carbon' }
    ];

    const columnGroupingModel = [
        {
            groupId: 'Data',
            description: 'Data',
            children: [{ field: 'name' }, { field: 'address' }, { field: 'agreement' }],
        },
        {
            groupId: 'Energy Use',
            children: [{ field: 'kWhYr' }],
        },
        {
            groupId: 'Home size',
            children: [{ field: 'sqft' }],
        },
        {
            groupId: 'Energy intensity',
            children: [{ field: 'kWhSqft' }],
        },
        {
            groupId: 'Carbon footprint',
            children: [{ field: 'carbonFormat' }],
        },
        {
            groupId: 'Audit',
            children: [{ field: 'agreementStatusFormat' }],
        },
        {
            groupId: 'Challenges',
            children: [{ field: 'challenges' }, { field: 'challenges_complete' }],
        },
        {
            groupId: 'ranking',
            description: '',
            children: [{ field: 'ranking' }],
        },
        {
            groupId: 'Progress vs goals',
            children: [{ field: 'energyGoal' }, { field: 'costGoal' }, { field: 'carbonGoal' }],
        },
    ];

    const [tableGoals] = useState({
        energy: {
            value: 0,
            color: '#000000'
        },
        carbon: {
            value: 0,
            color: '#000000'
        },
        cost: {
            value: 0,
            color: '#000000'
        }
    });

    useEffect(() => {
        getData();
        getCountsPanel();
    }, []);

    const getData = () => {
        props.setIsLoading(true);
        getUserData().then(data => {
            data.forEach((element, index) => {
                element.id = index + 1;
                element.kWhYr = element?.energyUse?.toLocaleString('en-US', { maximumFractionDigits: 2 });
                element.sqft = element.homeSize?.toLocaleString('en-US', { maximumFractionDigits: 2 });
                element.kWhSqft = element?.energyUse > 0 ? (element?.energyUse / element.homeSize)?.toLocaleString('en-US', { maximumFractionDigits: 2 }) : '0';
                element.carbonFormat = element?.carbon?.toLocaleString('en-US', { maximumFractionDigits: 2 });
                element.agreementStatusFormat = element.agreementStatus?.includes('Active') ? 'Yes' : 'No';
            });
            setRows(data);

            getPowerBIToken().then(data => {
                console.log(data);
                setToken(data);
            }).catch(console.log);
        }).catch(console.log)
            .finally(() => props.setIsLoading(false));
    }

    const getCountsPanel = () => {
        props.setIsLoading(true);
        getCountsPanelData()
            .then(data => {
                setPanelCounts(data);
            }).catch(console.log)
            .finally(() => props.setIsLoading(false));
    }

    return (
        <div className='container-fluid'>
            <h1 className='text-center'>DASHBOARD</h1>
            {Object.keys(token).length > 0 &&
                <div className='row mt-4 justify-content-center'>
                    <div className='col-md-10'>
                        <div className="container-card dashboard-card mt-2 h-100 p-2 ">
                            <PowerBiReport token={token} />
                        </div>
                    </div>
                </div>
            }

            {/* <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-3 mt-0 mt-md-2" >
                    <div className="container-card dashboard-card mt-2 h-100 p-2 ">
                        <div className="row pt-2 d-flex align-items-center justify-content-center">
                            <div className="col-md-5">
                                <div className="number-circle text-center">
                                    {periodName !== 'Last Year' && '$' + currentView?.differenceValue?.toFixed(0)}
                                    {periodName === 'Last Year' && '$' + currentView?.lastMonth_value?.toFixed(0)}
                                    {periodName !== 'Last Year' && <p>{2 > 3 ? 'more' : 'less'}</p>}
                                </div>
                            </div>
                            <div className="col-md-5 text-center">
                                <h2 className="text-center title-section title-section-card">{
                                    periodName == 'Weeks' ? 'LAST WEEK VS PREVIOUS WEEK' :
                                        periodName == 'Months' ? 'LAST MONTH VS PREVIOUS MONTH' :
                                            periodName == 'Week year' ? 'LAST WEEK VS LAST YEAR WEEK' :
                                                periodName == 'Month year' ? 'LAST MONTH VS LAST YEAR MONTH'
                                                    : periodName
                                }</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md- col-lg-4 mt-0 mt-md-2" >
                    <div className="container-card dashboard-card mt-2 h-100 p-0">
                        <div className='row'>
                            <div className='col-md-4'>
                                <h2 className="text-center title-section pt-4 title-section-card"><LightbulbIcon className='icon-house-type' /> {rows.length}</h2>
                                <h3 className="subtitle-section text-center">USERS</h3>
                                <h3 className="subtitle-section text-center strong">+70%</h3>
                            </div>
                            <div className='col-md-4'>
                                <h2 className="text-center title-section pt-4 title-section-card"><LightbulbIcon className='icon-house-type' />23,834</h2>
                                <h3 className="subtitle-section text-center">SESSIONS</h3>
                                <h3 className="subtitle-section text-center strong">+70%</h3>
                            </div>
                            <div className='col-md-4'>
                                <h2 className="text-center title-section pt-4 title-section-card"><LightbulbIcon className='icon-house-type' /> {panelCounts?.challenges ?? 0}</h2>
                                <h3 className="subtitle-section text-center">CHALLENGES</h3>
                                <h3 className="subtitle-section text-center strong">+100%</h3>
                            </div>
                        </div>
                        <hr className='w-75 text-center m-auto mt-2 mb-0' />
                        <h2 className="text-center title-section pt-2 title-section-card"><LightbulbIcon className='icon-house-type' /> {(periodData?.lastYear?.lastMonth_total / rows.reduce((a, b) => a + b.homeSize, 0))?.toLocaleString('en-US', { maximumFractionDigits: 2 })} kWh/sqft</h2>
                        <h3 className="subtitle-section text-center">Energy intensity (amount of energy/building)</h3>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg mt-0 mt-md-2" >
                    <div className="container-card dashboard-card mt-2 h-100">
                        <h2 className="text-center title-section pt-4 title-section-card"><ElectricBoltIcon className='icon-house-type' /> {(periodData?.lastYear?.lastMonth_total / 1000)?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? 0} MWh</h2>
                        <h3 className="subtitle-section text-center">Electric consumption for last 12 months</h3>
                        <hr className='w-75 text-center m-auto mt-2 mb-0' />
                        <h2 className="text-center title-section pt-2 title-section-card"><LightbulbIcon className='icon-house-type' /> {panelCounts?.virtual_audits ?? 0} VIRTUAL AUDITS</h2>
                        <h3 className="subtitle-section text-center">Energy intensity (amount of energy/building)</h3>
                    </div>
                </div>
            </div>
            <div className='row mt-4'>
                <div className="col-md-5">
                    <ChartContainer
                        setPeriodData={setPeriodData}
                        setCurrentView={setCurrentView}
                        periodName={periodName}
                        setPeriodName={setPeriodName}
                        functions={props.functions} />
                </div>
                <div className="col-md-3">
                    <div className="container-card card-content pb-3 h-100">
                        <h2 className="text-start title-section m-0">PROGRESS VS GOALS</h2>
                        <ChartGoal size='lg' tableGoals={tableGoals} />
                        <div className="row">
                            <div className="col-md-4 text-center">
                                <h3 className="mb-1">Energy</h3>
                                <span className="label-energy-s">
                                    22 %
                                </span>
                            </div>
                            <div className="col-md-4 text-center">
                                <h3 className="mb-1">Carbon</h3>
                                <span className="label-carbon-s">
                                    35 %
                                </span>
                            </div>
                            <div className="col-md-4 text-center">
                                <h3 className="mb-1">Cost</h3>
                                <span className="label-cost-s">
                                    22 %
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "" }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                    >
                        {rows.filter(w => w.latitude != null).map((row, index) => {
                            return (
                                <AnyReactComponent
                                    key={index}
                                    lat={row.latitude}
                                    lng={row.longitude}
                                    text={row.name}></AnyReactComponent>
                            )
                        })}
                    </GoogleMapReact>
                </div>
            </div>
            <hr />
            <div className='row mt-4'>
                <div className='col-md-12'>
                    <DataGrid rows={rows} columns={columnsDg}
                        experimentalFeatures={{ columnGrouping: true }}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[5, 10, 25, 50, 100]}
                        columnGroupingModel={columnGroupingModel}
                    />
                </div>
            </div> */}
        </div>
    );
}

export default NewHome;
