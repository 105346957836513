import { TextField, Fab } from '@mui/material';
import LogoPrincipal from '../../assets/logo-enerwisely.png';
import { useEffect, useState } from 'react';
import LoginIcon from '@mui/icons-material/Login';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { useNavigate } from "react-router-dom";
import { postLogin } from './../../components/AuthBase/AuthBase';
import { getIp } from './../../utils';
import { ePage } from './../../App';
import { eStorage, saveStorage, saveStorageJSON } from './../../services/StorageService';

function Login(props) {
    const [model, setModel] = useState({ email: '', password: '', site: 'control panel', ipAddress: '::0' });
    let navigate = useNavigate();

    useEffect(() => {
        sessionValid();
        getIp().then(ip => model.ipAddress = ip);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sessionValid = () => {
        if (localStorage.getItem("session"))
            navigate(ePage.home, { replace: true });
    }

    const handleSubmit = () => {
        props.setIsLoading(true);
        postLogin(model).then(data => {
            saveStorage(eStorage.session, true);
            saveStorage(eStorage.bearer, data.bearer);
            saveStorageJSON(eStorage.user, {
                customerId: data.customerId,
                email: data.email,
                eweMail: data.eweMail,
                preferredLang: data.preferredLang,
                user: data.user
            });
            if (props.setLogin) {
                props.setLogin(true);
            }
            navigate("/home", { replace: true });
        }).catch(() => {
            Notify.failure("Error intentando iniciar session", { position: 'center-bottom', });
        }).finally(() => {
            props.setIsLoading(false);
        });
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setModel(prevState => ({
            ...prevState, [name]: value
        }));
    }

    return (
        <div className='container'>
            <h1 className='text-center'>LOGIN / CONTROL PANEL</h1>
            <div className='row justify-content-md-center'>
                <div className='col-md-6 container-card pt-5 pb-5 text-center'>
                    <img className="logo-login" src={LogoPrincipal} alt="logo" />
                    <div className="row mt-4 justify-content-md-center">
                        <div className="col-md-8">
                            <TextField fullWidth size="small" name='email' value={model.email} id="user" onChange={handleChange} label="Email" />
                        </div>
                    </div>
                    <div className="row mt-3 justify-content-md-center">
                        <div className="col-md-8">
                            <TextField type="password" fullWidth size="small" name='password' value={model.password} onChange={handleChange} id="main-office" label="Password" />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <Fab variant="extended" color="primary" onClick={handleSubmit}>
                                <LoginIcon sx={{ mr: 1 }} />LOGIN
                            </Fab>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
