import { orchestratorApiInstance } from "./api";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

export const getParams = () => {
  let params;
  params = {
    DATASERVICES_URL: 'https://emm.enerwisely.com/dataservices/',
    ORCHESTRATOR_URL: 'https://emm.enerwisely.com/orchestrator/',
    CALCENGINE_URL: 'https://emm.enerwisely.com/calcengine/',
  }
  return params;
}

export const getIp = async () => {
  let response = await fetch('https://api.ipify.org?format=json')
  response = await response.json();
  return response.ip;
}

export const dateFormatOptions = {
  year: 'numeric', month: 'numeric', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  hour12: true,
  // timeZone: 'America/Los_Angeles'
};

export const getColors = () => {
  return [
    '#5D6D7E',
    '##2E86C1',
    '#5DADE2',
    '#2471A3',
    '#1A5276',
    '#5DADE2',
    '#2E86C1',
    '#2874A6',
    '#2471A3',
  ]
}

export const getState = () => {
  return [
    {
      "name": "Alabama",
      "value": "AL"
    },
    {
      "name": "Alaska",
      "value": "AK"
    },
    {
      "name": "American Samoa",
      "value": "AS"
    },
    {
      "name": "Arizona",
      "value": "AZ"
    },
    {
      "name": "Arkansas",
      "value": "AR"
    },
    {
      "name": "California",
      "value": "CA"
    },
    {
      "name": "Colorado",
      "value": "CO"
    },
    {
      "name": "Connecticut",
      "value": "CT"
    },
    {
      "name": "Delaware",
      "value": "DE"
    },
    {
      "name": "District Of Columbia",
      "value": "DC"
    },
    {
      "name": "Federated States Of Micronesia",
      "value": "FM"
    },
    {
      "name": "Florida",
      "value": "FL"
    },
    {
      "name": "Georgia",
      "value": "GA"
    },
    {
      "name": "Guam Gu",
      "value": "GU"
    },
    {
      "name": "Hawaii",
      "value": "HI"
    },
    {
      "name": "Idaho",
      "value": "ID"
    },
    {
      "name": "Illinois",
      "value": "IL"
    },
    {
      "name": "Indiana",
      "value": "IN"
    },
    {
      "name": "Iowa",
      "value": "IA"
    },
    {
      "name": "Kansas",
      "value": "KS"
    },
    {
      "name": "Kentucky",
      "value": "KY"
    },
    {
      "name": "Louisiana",
      "value": "LA"
    },
    {
      "name": "Maine",
      "value": "ME"
    },
    {
      "name": "Marshall Islands",
      "value": "MH"
    },
    {
      "name": "Maryland",
      "value": "MD"
    },
    {
      "name": "Massachusetts",
      "value": "MA"
    },
    {
      "name": "Michigan",
      "value": "MI"
    },
    {
      "name": "Minnesota",
      "value": "MN"
    },
    {
      "name": "Mississippi",
      "value": "MS"
    },
    {
      "name": "Missouri",
      "value": "MO"
    },
    {
      "name": "Montana",
      "value": "MT"
    },
    {
      "name": "Nebraska",
      "value": "NE"
    },
    {
      "name": "Nevada",
      "value": "NV"
    },
    {
      "name": "New Hampshire",
      "value": "NH"
    },
    {
      "name": "New Jersey",
      "value": "NJ"
    },
    {
      "name": "New Mexico",
      "value": "NM"
    },
    {
      "name": "New York",
      "value": "NY"
    },
    {
      "name": "North Carolina",
      "value": "NC"
    },
    {
      "name": "North Dakota",
      "value": "ND"
    },
    {
      "name": "Northern Mariana Islands",
      "value": "MP"
    },
    {
      "name": "Ohio",
      "value": "OH"
    },
    {
      "name": "Oklahoma",
      "value": "OK"
    },
    {
      "name": "Oregon",
      "value": "OR"
    },
    {
      "name": "Palau",
      "value": "PW"
    },
    {
      "name": "Pennsylvania",
      "value": "PA"
    },
    {
      "name": "Puerto Rico",
      "value": "PR"
    },
    {
      "name": "Rhode Island",
      "value": "RI"
    },
    {
      "name": "South Carolina",
      "value": "SC"
    },
    {
      "name": "South Dakota",
      "value": "SD"
    },
    {
      "name": "Tennessee",
      "value": "TN"
    },
    {
      "name": "Texas",
      "value": "TX"
    },
    {
      "name": "Utah",
      "value": "UT"
    },
    {
      "name": "Vermont",
      "value": "VT"
    },
    {
      "name": "Virgin Islands",
      "value": "VI"
    },
    {
      "name": "Virginia",
      "value": "VA"
    },
    {
      "name": "Washington",
      "value": "WA"
    },
    {
      "name": "West Virginia",
      "value": "WV"
    },
    {
      "name": "Wisconsin",
      "value": "WI"
    },
    {
      "name": "Wyoming",
      "value": "WY"
    }
  ];
}

export const getUniqueVisitorId = () => {
  const response = orchestratorApiInstance.get(`smt2/uniqueVisitorIdentifier`)
    .then(({ data }) => {
      return data.uniqueVisitorIdentifier;
    });
  return response;
}

export const getStorageJSON = (key) => {
  return JSON.parse(localStorage.getItem(key));
}

export const exportExcel = async (fileName, datalist, headerStyle) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const colLetter = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

  const ws = XLSX.utils.json_to_sheet(datalist);

  if (headerStyle) {
    const keys = Object.keys(datalist[0]);
    keys.forEach((item,index)=>{
      ws[`${colLetter[index]}1`].s = headerStyle;
    });
  }

  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, `${fileName}${fileExtension}`);
}


export const getYearMonthName = (date) => {
  console.log(date);
  let language = 'en';

  if (date === undefined)
    return "";

  let monthSplit = date.split('/');
  let monthName = '';

  switch (monthSplit[0]) {
    case '01': language === 'en' ? monthName = 'January' : monthName = 'Enero'; break;
    case '02': language === 'en' ? monthName = 'February' : monthName = 'Febrero'; break;
    case '03': language === 'en' ? monthName = 'March' : monthName = 'Marzo'; break;
    case '04': language === 'en' ? monthName = 'April' : monthName = 'Abril'; break;
    case '05': language === 'en' ? monthName = 'May' : monthName = 'Mayo'; break;
    case '06': language === 'en' ? monthName = 'June' : monthName = 'Junio'; break;
    case '07': language === 'en' ? monthName = 'July' : monthName = 'Julio'; break;
    case '08': language === 'en' ? monthName = 'August' : monthName = 'Agosto'; break;
    case '09': language === 'en' ? monthName = 'September' : monthName = 'Septiembre'; break;
    case '10': language === 'en' ? monthName = 'October' : monthName = 'Octubre'; break;
    case '11': language === 'en' ? monthName = 'November' : monthName = 'Noviembre'; break;
    case '12': language === 'en' ? monthName = 'December' : monthName = 'Diciembre'; break;
    default: language === 'en' ? monthName = 'unknown' : monthName = 'desconocido'; break;
  }

  let currentMonth = new Date().getMonth();
  let year = new Date().getFullYear();
  if (monthSplit.length === 3)
    monthName = `${monthName} ${monthSplit[2]}`;
  else if (monthSplit.length === 2) {
    if (monthSplit[0] === '12' && (currentMonth === 0 || currentMonth === 1))
      year = year - 1;
    monthName = `${monthName} ${year}`;
  }

  return monthName;
}