import { Routes, Route } from "react-router-dom";
import LoginPage from '../../pages/Login/Login';
import NewHome from "../../pages/NewHome/NewHome";
import Order from "../../pages/Order/Order";
import ProductEdit from "../../pages/Products/ProductEdit";
import ProductNew from "../../pages/Products/ProductNew";
import ProductList from "../../pages/Products/ProductList";
import Gamification from "../../pages/Gamification/Gamification";
import AddChallenge from "../../pages/Gamification/AddChagellenge";
import EditChallenge from "../../pages/Gamification/EditChagellenge";
import Recommendations from "../../pages/Recommendations/Recommendations";
import AddRecommendation from "../../pages/Recommendations/AddRecommendation";
import EditRecommendation from "../../pages/Recommendations/EditRecommendation";
import Badges from "../../pages/Badges/Badges";
import AddBadge from "../../pages/Badges/AddBadge";
import PrivateRoute from "./PrivateRoute";
import ProgressChallenge from "../../pages/Gamification/ProgressChallenge";
import EditBadge from "../../pages/Badges/EditBadge";
import SmartDeviceIndex from "../../pages/SmartDevices/SmartDeviceIndex";
import SmartDeviceForm from "../../pages/SmartDevices/SmartDeviceForm";

function RoutesApp(props) {

    return (
        <Routes>
            <Route path="/" element={<LoginPage setIsLoading={props.setIsLoading} isLoading={props.isLoading} setLogin={props.setLogin} />} />
            <Route path="/login" element={<LoginPage setIsLoading={props.setIsLoading} isLoading={props.isLoading} setLogin={props.setLogin} />} />
            <Route element={<PrivateRoute />}>
                <Route path="/home" element={<NewHome setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
                <Route path="/gamification" element={<Gamification setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
                <Route path="/add-challenge" element={<AddChallenge setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
                <Route path="/edit-challenge" element={<AddChallenge setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
                <Route path="/challenge/progress/:id" element={<ProgressChallenge setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
                <Route path="/recommendations" element={<Recommendations setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
                <Route path="/add-recommendation" element={<AddRecommendation setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
                <Route path="/edit-recommendation/:id" element={<EditRecommendation setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
                <Route path="/badges" element={<Badges setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
                <Route path="/add-badge" element={<AddBadge setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
                <Route path="/edit-badge/:id" element={<EditBadge setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
                <Route path="/products" element={<ProductList setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
                <Route path="/add-product" element={<ProductNew setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
                <Route path="/edit-product" element={<ProductEdit setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
                <Route path="/orders" element={<Order setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
                <Route path="/smart-device" element={<SmartDeviceIndex setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
                <Route path="/smart-device/add" element={<SmartDeviceForm setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
                <Route path="/smart-device/edit/:id" element={<SmartDeviceForm setIsLoading={props.setIsLoading} isLoading={props.isLoading} />} />
            </Route>
        </Routes>
    )
}

export default RoutesApp;