/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ePage } from '../../App';
import { useNavigate } from "react-router-dom";
import { getCategoriesList, getRecommendationsList } from './../../components/Recommendations/recommendationsBase';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { AntSwitch } from '../../components/Shared/AntSwitch';
import { DataGrid } from '@mui/x-data-grid';


function Recommendations(props) {
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const [cost] = useState(['pure - behavior', 'low - invest', 'medium - invest', 'high - invest']);

    const columnsDg = [
        { field: "id", headerName: "#", width: 50, },
        { field: "badge", headerName: "badge", width: 140, },
        { field: "category", headerName: "category", width: 150, },
        { field: "cost", headerName: "Cost", width: 160, },
        { field: "points", headerName: "points", width: 100, },
        { field: "recommendation", headerName: "recommendation", width: 350, },
        {
            field: "active",
            headerName: "Status",
            editable: false,
            width: 200,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={1} alignItems="center">
                        Disable
                        <AntSwitch className='ms-1 me-1' checked={true} onChange={() => onChangeSwicth(params.row)} />
                        Enable
                    </Stack>
                );
            }
        },
        {
            field: "action",
            headerName: "Action",
            editable: false,
            renderCell: (params) => {
                return (
                    <IconButton aria-label="edit" onClick={() => handleEdit(params.row.recordId)}><EditIcon color="secondary" /></IconButton>
                );
            }
        },
    ];

    useEffect(() => {
        getApiList();
        getCategories();
    }, []);

    const getApiList = () => {
        props.setIsLoading(true);
        getRecommendationsList()
            .then(data => {
                const formatdata = data.map((item, index) => {
                    item.recordId = item.id;
                    item.id = index+1;
                    item.active = '';
                    item.action = '';
                    item.cost = cost[item.cost];
                    return item;
                });
                setRows(formatdata);
            }).catch(console.log)
            .finally(() => props.setIsLoading(false));
    }

    const getCategories = () => {
        getCategoriesList()
            .then(resp => {
                if (resp) {
                    resp.unshift({
                        key: "All",
                        value: ""
                    })
                }
            })
            .catch(console.log)
    }

    const handleCreate = () => {
        navigate(ePage.addRecommendation);
    }

    const handleEdit = (id) => {
        navigate(`${ePage.editRecommendation}/${id}`);
    }

    const onChangeSwicth = (field)=>{
        console.log('field', field);
    }

    return (
        <div className='container'>
            <h1 className='text-center'>RECOMMENDATIONS</h1>
            <div className='row justify-content-md-center'>
                <div className='col-md-12 text-center'>
                    <Button variant="contained" endIcon={<AddIcon />} onClick={handleCreate}>New Recommendation</Button>
                </div>
            </div>
            <hr />
            <div className='row mt-3'>
                <div className='col-md-12'>
                    <DataGrid rows={rows} columns={columnsDg}
                        initialState={{

                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[5, 10, 25, 50, 100]}
                    />
                </div>
            </div>
        </div>
    );
}

export default Recommendations;
