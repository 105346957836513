/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef,useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Slide } from '@mui/material';
import { getCustomerChallengeImages } from '../../components/Challenge/challengeBase';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { getParams } from './../../utils';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalImage(props) {
  const [images, setImages] = useState([]);
  useEffect(() => {
    getCustomerChallengeImages(props.customerChallengeId).then(resp => {
      if (resp) {
         setImages(resp);
      }
  }).catch(console.log);
  }, []);

  const handleClose = () => {
    props.setModal({ open: false });
  };

  const handleSubmit = () => {
    handleClose();
  }

  return (
    <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'} keepMounted onClose={handleClose}>
      <DialogTitle className='text-center'>Images</DialogTitle>
      <hr className='m-0' />
      <DialogContent>
        <div className='row mt-2 justify-content-md-center'>
          <div className="col-md-8 text-center">
            <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
              {images.map((img, index) => {
                return (
                  <div key={index}>
                    <img src={`${getParams().DATASERVICES_URL}/challenge/image/${img.image}`} alt={`image_${index}`} />
                  </div>
                )
              })}
            </Carousel>
          </div>
        </div>
      </DialogContent>
      <hr className='mb-0 mt-2' />
      <DialogActions className='justify-content-md-center'>
        <Button className='btn-secondary me-1' onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
