
import { panelApiInstance } from '../../api';
import { endpoint } from '../../api.endpoints';
import { getParams } from '../../utils';

export const getList = () => {
  return panelApiInstance.get(endpoint.smartDevice)
    .then((response) => {
      return (response) ? response.data : [];
    });
}

export const getById = (id) => {
  return panelApiInstance.get(`${endpoint.smartDevice}${id}`)
    .then((response) => {
      return (response) ? response.data : null;
    });
}

export const save = (model) => {
  return panelApiInstance.post(endpoint.smartDevice, model)
    .then((response) => {
      return (response) ? response.data : null;
    });
}

export const update = (model) => {
  return panelApiInstance.post(endpoint.smartDeviceUpdate, model)
    .then((response) => {
      return (response) ? response.data : null;
    });
}

export const customerCustom = ()=>{
  return panelApiInstance.get(endpoint.customerSmartDevice)
    .then((response) => {
      return (response) ? response.data : [];
    });
}

export const customerCustomById = (customerId) => {
  return panelApiInstance.get(`${endpoint.customerSmartDeviceByCustomer}${customerId}`)
    .then((response) => {
      return (response) ? response.data : [];
    });
}

export const ImageBaseUrl = `${getParams().DATASERVICES_URL}${endpoint.smartDevice}image`;