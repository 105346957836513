import { panelApiInstance } from './../../api';
import { endpoint } from './../../api.endpoints';

export const getBadgeList = () => {
  return panelApiInstance.get(endpoint.bagesAll)
    .then((response) => {
      return (response) ? response.data : [];
    });
}

export const getBadgedById = (id) => {
  return panelApiInstance.get(`${endpoint.bagedById}/${id}`)
    .then((response) => {
      return (response) ? response.data : null;
    });
}

export const upsertBadge = (badge) =>{
  return panelApiInstance.post(endpoint.badgeUpsert, badge)
    .then((response) => {
      return (response) ? response.data : null;
    });
 }