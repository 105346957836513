/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useRef, useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { useNavigate, useSearchParams } from "react-router-dom";
import { ePage } from '../../App';
import ModalImage from '../../shared/ModalImage';
import { getProductById, saveProductPicture, updateProduct } from './../../components/Products/ProductBase';
import { getParams } from './../../utils';
import ImageChallenge from '../../assets/trophy.png';

export default function ProductEdit(props) {
  const formRef = useRef();
  const [searchParams] = useSearchParams();
  const [model, setModel] = useState({
    id: '', name: '', description: '', cost: '', onlyBehavior: '', energyReduction: '', dollarAmount: '', equipmentLifeExpetacy: '', usCimateZone: '',
    percentageProjectCost: '', maximumAmmountCredited: '', descriptionRebate: '', amount: '', eligibility: '', qrCode: '', disclaimer: '',
    status: true, picture: ''
  });
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const fileElement = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [productId] = useState(searchParams.get("id"));
  const [picture, setPicture] = useState(null);

  useEffect(() => {
    props.setIsLoading(true);
    getProductById(productId)
      .then(resp => {
        if (!resp) {
          Notify.failure('Product doesn\'t exists', { position: 'center-bottom', });
          navigate(ePage.products)
        } else {
          setModel(resp);
        }
        if (resp.picture) {
          setPicture(`${getParams().DATASERVICES_URL}/product/image/${resp.picture}`);
        } else {
          setPicture(ImageChallenge);
        }
      }).catch(console.log)
      .finally(() => props.setIsLoading(false));
  }, []);


  const handleChange = (event) => {
    const { name, value } = event.target;
    setModel(prevState => ({
      ...prevState, [name]: value
    }));
  }

  const handleAddPicture = () => {
    fileElement.current.click();
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const totalSizeMB = file.size / Math.pow(2024, 2)
      if (totalSizeMB > 2.024) {
        Notify.failure('File is too big!', { position: 'center-bottom', });
      } else {
        let imageDataUrl = await readFile(file)
        setImageSrc(imageDataUrl);
        setModal(true);
      }
    }
  }

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  const handleSubmit = () => {
    props.setIsLoading(true);
    const promises = [updateProduct(model)];
    if (croppedImage) {
      const imgSlices = croppedImage.split(',');
      if (Array.isArray(imgSlices)) {
        // model.picture = imgSlices[1];
        promises.push(saveProductPicture({ id: model.id, base64: imgSlices[1] }))
      }
    }

    Promise.all(promises)
      .then((resp) => {
        showAndRedirect();
      }).catch(() => {
        Notify.failure('Error saving product, please, try later!', { position: 'center-bottom', });
      })
      .finally(() => props.setIsLoading(false));
  };

  const showAndRedirect = () => {
    Notify.success('Product saved!', { position: 'center-bottom' });
    navigate(ePage.products);
  }


  return <div className='container mt-5'>
    <h1 className='text-center'>EDIT PRODUCT</h1>
    <ModalImage open={modal} setModalOpen={setModal} croppedImage={croppedImage} setCroppedImage={setCroppedImage} imageSrc={imageSrc} />
    <div className='row mt-3 justify-content-md-center'>
      <div className='col-md-12'>
        <ValidatorForm ref={formRef} onSubmit={handleSubmit} onError={errors => console.log(errors)}>
          <div className='container-card card-content'>
            <div className='row'>
              <div className='col-md-6'>
                <div className="row justify-content-md-center mb-4">
                  <div className="col-md-9">
                    <h6 className='text-center strong'>Name</h6>
                    <TextField fullWidth size="small" name='name' onChange={handleChange} value={model.name} type='text' className="text-terms" label="Name" />
                  </div>
                  <div className='col-md-3'>
                    {picture && !croppedImage && <img className="img-fluid challenge" src={picture} alt="product" onError={(e) => { e.target.src = ImageChallenge }} />}
                    {croppedImage && <img src={croppedImage} alt='photo' width="100" />}
                    <input type="file" style={{ display: 'none' }} max-file-size="1024" onChange={onFileChange} accept="image/*" ref={fileElement} />
                    <Button variant="contained" endIcon={<PhotoCameraIcon />} onClick={handleAddPicture}>Add</Button>
                    <input type='file' label="Picture" style={{ display: 'none' }} />
                  </div>
                </div>
                <div className="row justify-content-md-center mb-3">
                  <div className="col-md-12">
                    <h6 className='text-center strong'>Description</h6>
                    <TextField fullWidth size="small" name='description' onChange={handleChange} value={model.description} className="text-terms" multiline rows={2} label="Description" />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <h6 className='text-center strong'>Costs</h6>
                  </div>
                </div>
                <div className="row justify-content-md-center mb-3">
                  <div className="col-md-6">
                    <TextValidator autoComplete="nope" fullWidth size="small" onChange={handleChange} value={model.cost} name="cost" label='Cost ($)' validators={['required']} errorMessages={'cost requerido'} />
                  </div>
                  <div className="col-md-6">
                    <TextValidator autoComplete="nope" fullWidth size="small" name="onlyBehavior" value={model.onlyBehavior} onChange={handleChange} label='Only behavior change' validators={['required']} errorMessages={'Only behavior change requerido'} />
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-12'>
                    <h6 className='text-center strong'>Savings</h6>
                  </div>
                </div>
                <div className="row justify-content-md-center mb-2">
                  <div className="col-md-6">
                    <TextValidator autoComplete="nope" fullWidth size="small" name="energyReduction" value={model.energyReduction} onChange={handleChange} label='Energy reduction %' validators={['required']} errorMessages={'Energy reduction requerido'} />
                  </div>
                  <div className="col-md-6">
                    <TextValidator autoComplete="nope" fullWidth size="small" name="dollarAmount" value={model.dollarAmount} onChange={handleChange} label='Dollar ammount' validators={['required']} errorMessages={'Dollar ammount requerido'} />
                  </div>
                </div>
                <div className="row justify-content-md-center mb-3">
                  <div className="col-md-6">
                    <TextValidator autoComplete="nope" fullWidth size="small" name="equipmentLifeExpetacy" value={model.equipmentLifeExpetacy} onChange={handleChange} label='Equipment life expectacy' validators={['required']} errorMessages={'Equipment life expectacy requerido'} />
                  </div>
                  <div className="col-md-6">
                    <TextValidator autoComplete="nope" fullWidth size="small" name="usCimateZone" value={model.usCimateZone} onChange={handleChange} label='US climate zone' validators={['required']} errorMessages={'US climate zone requerido'} />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-12'>
                    <h6 className='text-center strong'>Tax Credits</h6>
                  </div>
                  <div className="row justify-content-md-center mb-2">
                    <div className="col-md-6">
                      <TextValidator autoComplete="nope" fullWidth size="small" name="percentageProjectCost" value={model.percentageProjectCost} onChange={handleChange} label='% of project cost' validators={['required']} errorMessages={'% of project cost requerido'} />
                    </div>
                    <div className="col-md-6">
                      <TextValidator autoComplete="nope" fullWidth size="small" name="maximumAmmountCredited" value={model.maximumAmmountCredited} onChange={handleChange} label='maximum ammount credited ($)' validators={['required']} errorMessages={'US climate zone requerido'} />
                    </div>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-12'>
                    <h6 className='text-center strong'>Rebates / Vouchers / Incentives</h6>
                  </div>
                  <div className="row justify-content-md-center mb-4">
                    <div className="col-md-12">
                      <TextValidator autoComplete="nope" className="text-terms" multiline rows={2} fullWidth size="small" name="descriptionRebate" value={model.descriptionRebate} onChange={handleChange} label='Description' validators={['required']} errorMessages={'Description requerido'} />
                    </div>
                  </div>
                  <div className="row justify-content-md-center mb-2">
                    <div className="col-md-6">
                      <TextValidator autoComplete="nope" fullWidth size="small" name="amount" value={model.amount} onChange={handleChange} label='Ammount $' validators={['required']} errorMessages={'Ammount requerido'} />
                    </div>
                    <div className="col-md-6">
                      <TextValidator autoComplete="nope" fullWidth size="small" name="qrCode" value={model.qrCode} onChange={handleChange} label='QR code / Link' />
                    </div>
                  </div>
                  <div className="row justify-content-md-center mb-2">
                    <div className="col-md-6">
                      <TextValidator autoComplete="nope" fullWidth size="small" name="eligibility" value={model.eligibility} onChange={handleChange} label='Eligibility' validators={['required']} errorMessages={'Eligibility requerido'} />
                    </div>
                    <div className="col-md-6">
                      <TextValidator autoComplete="nope" fullWidth size="small" name="disclaimer" value={model.disclaimer} onChange={handleChange} label='Disclaimer' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row justify-content-md-center'>
              <div className='col-md-12 text-center'>
                <Button variant="contained" type='submit'>Save</Button>
              </div>
            </div>
          </div>
        </ValidatorForm>
      </div>
    </div>
  </div>
}