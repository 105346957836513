/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { Slide } from '@mui/material';
import { forwardRef } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalQuiz(props) {

  useEffect(() => {
  }, []);

  const handleClose = () => {
    props.setModal({ open: false });
  };

  const handleSubmit = () => {
    handleClose();
  }

  return (
    <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'} keepMounted onClose={handleClose}>
      <DialogTitle className='text-center'>Quiz Response</DialogTitle>
      <hr className='m-0' />
      <DialogContent>
        <div className='row mt-2 justify-content-md-center'>
          <div className="col-md-8 text-center">
            {props.questions?.map((value, index) => {
              return (<div key={index}>
                <div className='row justify-content-md-center mt-4'>
                  <div className='col-md-12'>
                    {index + 1}. {value.questionValue}
                  </div>
                </div>
                <div className='row justify-content-md-center'>
                  <div className='col-md-12 text-center'>
                    {value.typeValue === 'short' &&
                      < TextField label="Response" variant="outlined" />
                    }
                    {value.typeValue === 'paragraph' &&
                      <TextField multiline rows={3} label="Response" variant="outlined" />
                    }
                    {value.typeValue === 'multiple' &&
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel value="1" control={<Radio />} label={value.options[0]?.value} />
                          <FormControlLabel value="2" control={<Radio />} label={value.options[1]?.value} />
                          {value.options[2]?.value &&
                            <FormControlLabel value="" control={<Radio />} label={value.options[2]?.value} />}
                        </RadioGroup>
                      </FormControl>
                    }
                  </div>
                </div>
              </div>
              )
            })}
          </div>
        </div>
      </DialogContent>
      <hr className='mb-0 mt-2' />
      <DialogActions className='justify-content-md-center'>
        <Button className='btn-secondary me-1' onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
