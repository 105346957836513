import { panelApiInstance } from "../../api";
import { eStorage, getStorage } from "../../services/StorageService";



export const ePeriod = {
    lastWeekVSPrevious: 'lastWeekvsPrevious',
    lastMonthVSPrevious: 'lastMonthvsPrevious',
    lastWeekVSLastYear: 'lastWeekvsLastYear',
    lastMonthVSLastYear: 'lastMonthvsLastYear',
    lastYear: 'lastYear',
}

export const eMetric = {
    energy: 'kwh',
    carbon: 'carbon',
    cost: 'dollars'
}

export const getPeriodData = (ePeriod, viewType) => {

    const response = panelApiInstance.get(`/panel/periodToPeriod/${ePeriod}?view=${viewType}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getYearPeriodData = (viewType) => {

    const response = panelApiInstance.get(`/panel/periodToPeriod/lastYear?view=${viewType}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}


export const getUserData = () => {

    const response = panelApiInstance.get(`/panel/user/`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getCountsPanelData = () => {
    const response = panelApiInstance.get(`/panel/enerwisely-panel/countsPanel/`)
        .then((response) => {
            return (response) ? response.data : {};
        });
    return response;
}

export const getPowerBIToken = () => {
    const response = panelApiInstance.get(`/microsoft/token`)
        .then((response) => {
            return (response) ? response.data : {};
        });
    return response;
}
