/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ePage } from '../../App';
import { useNavigate } from "react-router-dom";
import { getBadgeList } from './../../components/badges/badgesBase';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { AntSwitch } from '../../components/Shared/AntSwitch';
import ModalQuestionYesNo from './../../shared/ModalQuestionYesNo';
import { DataGrid } from '@mui/x-data-grid';

function Badges(props) {
    const [rows, setRows] = useState([]);
    const [modal, setModal] = useState({ open: false, title: '', message: '', extraData: {} });
    const navigate = useNavigate();

    const columnsDg = [
        { field: "id", headerName: "Id", width: 150, },
        { field: "name", headerName: "Name", width: 140, },
        {
            field: "",
            headerName: "Icon",
            editable: false,
            width: 100,
            height: 250,
            renderCell: (params) => {
                return (
                    <img width="50" src={params.row.icon} alt={params.row.title?.en} />
                );
            }
        },
        { field: "descriptionEn", headerName: "Description en", width: 300, },
        { field: "descriptionEs", headerName: "Description es", width: 300, },
        {
            field: "active",
            headerName: "Status",
            editable: false,
            width: 200,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={1} alignItems="center">
                        Disable
                        <AntSwitch className='ms-1 me-1' checked={params.row.status ?? false} onChange={(event) => changeSwitch(params.row)} />
                        Enable
                    </Stack>
                );
            }
        },
        {
            field: "action",
            headerName: "Action",
            editable: false,
            renderCell: (params) => {
                return (
                    <IconButton aria-label="edit" onClick={() => handleEdit(params.row.id)}><EditIcon color="secondary" /></IconButton>
                );
            }
        },
    ];

    useEffect(() => {
        getBadgesList();
    }, []);

    const getBadgesList = () => {
        props.setIsLoading(true);
        getBadgeList()
            .then(data => {
                setRows(data.map((m, inx) => {
                    m.index = inx;
                    m.descriptionEn = m.description.en;
                    m.descriptionEs = m.description.es;
                    return m;
                }));
            }).catch(console.log)
            .finally(() => props.setIsLoading(false));
    }

    const changeSwitch = (row) => {
        setModal({ open: true, title: 'Confirm', message: `Are you sure you want to change status: ${row.name}?`, extraData: { row } });
    }

    const modalYes = (event) => {
        props.setIsLoading(true);
        console.log(event);
        const product = event.extraData.row;
        product.status = !product.status;
        /* updateProduct(product)
             .then(() => {
                 Notify.success('status changed successfully!');
                 getApiList();
             })
             .catch(() => {
                 Notify.failure('Error trying to change status');
                 props.setIsLoading(false);
             })*/
        setModal({ open: false });
    }

    const modalNo = (event) => {
        setModal({ open: false });
    }

    const handleCreate = () => {
        navigate(ePage.AddBadge);
    }

    const handleEdit = (id) => {
        navigate(`${ePage.EditBadge}/${id}`);
    }

    return (
        <div className='container mt-4'>
            <h1 className='text-center mt-5'>BADGES</h1>
            <div className='row justify-content-md-center'>
                <div className='col-md-12 text-center'>
                    <Button variant="contained" endIcon={<AddIcon />} onClick={handleCreate}>New Badge</Button>
                </div>
            </div>
            <ModalQuestionYesNo modal={modal} message="Are you sure you want to clone this challenge?" handleOk={modalYes} handleCancel={modalNo} />
            <hr />
            <div className='col-md-12'>
                <DataGrid rows={rows} columns={columnsDg}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                />
            </div>
        </div>
    );
}

export default Badges;
