/* eslint-disable react-hooks/exhaustive-deps */
import "./ModalImage.css";
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide, Button } from '@mui/material';
import { forwardRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import { getCroppedImg } from './canvasUtils'


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ModalImage(props) {

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [rotation] = useState(0);

  const onCropChange = (crop) => {
    setCrop(crop)
  }

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }

  const onZoomChange = (zoom) => {
    setZoom(zoom)
  }

  const handleClose = () => {
    props.setCroppedImage(null);
    props.setModalOpen(false);
  }

  const handleContinue = async () => {
    try {
      const croppedImage = await getCroppedImg(
        props.imageSrc,
        croppedAreaPixels,
        rotation
      );
      props.setCroppedImage(croppedImage);
      props.setModalOpen(false);
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'sm'} keepMounted onClose={handleClose}>
      <DialogTitle className='text-center'>&nbsp;</DialogTitle>
      <hr className='m-0' />
      <DialogContent>
        <div className='row mt-2 justify-content-md-center'>
          <div className='col-md-12'>
            <div className="modal-avatar-crop-container">
              <Cropper
                image={props.imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={5 / 4}
                cropShape={props.cropShape ?? 'rect'}
                showGrid={false}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
              />
            </div>
            <div className="modal-avatar-controls">
              <Slider
                value={zoom}
                defaultValue={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => onZoomChange(zoom)}
                classes={{ container: 'modal-avatar-slider' }}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <hr className='mb-0 mt-2' />
      <DialogActions className='justify-content-md-center'>
        <Button className='btn-primary' onClick={handleContinue}>continue</Button>
      </DialogActions>
    </Dialog>
  );
}