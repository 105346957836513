
import { endpoint } from '../../api.endpoints';
import { panelApiInstance } from './../../api';

export const postProduct = (model) => {
    return panelApiInstance.post(endpoint.product, model)
        .then((response) => {
            return (response) ? response.data : null;
        });
}

export const getProductList = () => {
    return panelApiInstance.get(endpoint.product)
        .then((response) => {
            return (response) ? response.data : [];
        });
}

export const getProductById = (id) => {
    return panelApiInstance.get(`${endpoint.product}${id}`)
        .then((response) => {
            return (response) ? response.data : null;
        });
}

export const saveProductPicture = (model) => {
    return panelApiInstance.post(endpoint.productPicture, model)
        .then((response) => {
            return (response) ? response.data ?? '' : '';
        });
}

export const updateProduct = (model) => {
    return panelApiInstance.post(endpoint.productUpdate, model)
        .then((response) => {
            return (response) ? response.data : null;
        });
}

export const bulkStatusProduct = (status) => {
    return panelApiInstance.post(`${endpoint.productBulkStatus}${status}`)
        .then((response) => {
            return (response) ? response.data : null;
        });
}