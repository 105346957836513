import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";

function PrivateRoute(props) {
  const isAuth = localStorage.getItem('session');
  let location = useLocation();
  if (!isAuth || isAuth === 'false') {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return <Outlet />;
}

export default PrivateRoute;