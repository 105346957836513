import LogoWhite from './assets/logo-header.png';
import './App.css';
import { Suspense, useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Loading from './components/Shared/Loading';
import MenuPrincipal from './components/Shared/MenuPrincipal';
import RoutesApp from './components/Shared/RoutesApp';

export const ePage = {
  login: '/login',
  home: '/home',
  dashboard: '/dashboard',
  plan: '/plan',
  event: "/event",
  subscription: '/subscriptions',
  subscriptionForm: '/subscriptions/form',
  addChallenge: '/add-challenge',
  editChallenge: '/edit-challenge',
  addRecommendation: '/add-recommendation',
  editRecommendation: '/edit-recommendation',
  recommendation: '/recommendations',
  AddBadge: '/add-badge',
  Editbadge: "/edit-badge",
  badges: "/badges",
  EditBadge: '/edit-badge',
  addProduct: '/add-product',
  editProduct: '/edit-product',
  products: '/products',
  orders: '/orders',
  smartDevice:'/smart-device',
  smartDeviceAdd: '/smart-device/add',
  smartDeviceEdit: '/smart-device/edit',
}

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [login, setLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (localStorage.getItem("session")) {
      setLogin(true);
    }
  }, []);

  return (
    <div className="App-header">
      {isLoading && <Loading />}
      <Suspense fallback={<loading />}>
        {login && <AppBar component="nav">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              <img className='logo-navbar' src={LogoWhite} alt="logo" />
            </Typography>
            <MenuPrincipal setLogin={setLogin} />
          </Toolbar>
        </AppBar>}
        <RoutesApp setIsLoading={setIsLoading} isLoading={isLoading} setLogin={setLogin} />
      </Suspense>
    </div >
  );
}

export default App;
