import { Link } from "react-router-dom";
import {Button, Box} from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

function MenuPrincipal(props) {

    const handleLogout = () => {
        localStorage.clear();
        props.setLogin(false);
        window.location.href = "/login";
    }

    return (
        <Box className='top-menu' sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Button component={Link} to="/home">
                Home
            </Button>
            <Button component={Link} to="/gamification">
                Challenges
            </Button>
            <Button component={Link} to="/recommendations">
                Recommendations
            </Button>
            <Button component={Link} to="/badges">
                Badges
            </Button>
            <Button component={Link} to="/products">
                Products
            </Button>
            <Button component={Link} to="/orders">
                Orders
            </Button>
            <Button component={Link} to="/smart-device">
                Smart devices
            </Button>
            <Button onClick={handleLogout}>
                <PowerSettingsNewIcon />
            </Button>
        </Box>
    )
}

export default MenuPrincipal;