/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from 'react';
import { Button, TableContainer, Avatar, TableHead, TableRow, TableCell, Table, TableBody } from '@mui/material';
import { getChallengeById, getCustomerChallengeCustom } from '../../components/Challenge/challengeBase';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useParams } from "react-router-dom";
import ModalAward from './ModalAward';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ModalQuiz from './ModalQuiz';
import ModalImage from './ModalImage';
import ModalQuestionYesNo from '../../shared/ModalQuestionYesNo';

function ProgressChallenge(props) {
    const { id } = useParams();
    const [bests, setBests] = useState([]);
    const [challenge, setChallenge] = useState({});
    const [modalWinner, setModalWinner] = useState({ open: false, reload: false });
    const [modalQuiz, setModalQuiz] = useState({ open: false });
    const [modalImage, setModalImage] = useState({ open: false });
    const [modal, setModal] = useState({ open: false, title: '', message: '', extraData: {} });

    useEffect(() => {
        getRanking();
        getChallenge();
    }, []);

    useEffect(()=>{
        if(modalWinner && modalWinner.reload) {
            getRanking();
        }
    }, [modalWinner]);

   /* const getCustomerImages = ()=>{
        getCustomerChallengeImages()
    }*/

    const getRanking = () => {
        getCustomerChallengeCustom(id).then(resp => {
            if (resp) {
                setBests(resp.map(m => ({ name: `${m.name} ${m.lastname}`, points: m.points, status: m.status, quiz: m.quiz, customerChallengeId: m.customerChallengeId })));
            }
        }).catch(console.log);
    }

    const getChallenge = () => {
        getChallengeById(id).then(resp => {
            if (resp) {
                let data = resp[0];
                setChallenge(data);
            }
        })
    }
    const handleModalWinner = () => {
        setModalWinner({ open: true });
    }

    const handleModalQuiz = () => {
        setModalQuiz({ open: true });
    }

    const handleModalImage = (row) => {
        console.log(row);
        setModalImage({ open: true });
    }

    const handleFinish = () => {
        setModal({ open: true, title: 'Confirm', message: `Are you sure you want to close the challenge?`});
    }

    const modalYes = (event) => {
        setModal({ open: false });
    }

    const modalNo = (event) => {
        setModal({ open: false });
    }

    return (
        <div className='container'>
            <ModalQuestionYesNo modal={modal} message="Are you sure you want to close the challenge?" handleOk={modalYes} handleCancel={modalNo} ></ModalQuestionYesNo>
            <ModalAward open={modalWinner.open} setModal={setModalWinner} challengeId={id} modal={modalWinner} ></ModalAward>
            <div className='row'>
                <div className='col-md-9'>
                    <h2>{challenge?.name}</h2>
                </div>
                <div className='col-md-3 text-end'>
                    <Button variant="contained" endIcon={<DoneAllIcon />} onClick={handleFinish}>Finish</Button>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className="col-md-8">
                    <div className='container-card card-content'>
                        <h2 className="text-start">RANKING OF PARTICIPANTS</h2>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Points</TableCell>
                                        <TableCell>Quiz</TableCell>
                                        <TableCell>Images</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bests.map((row, index) => (
                                        <TableRow key={index}>
                                            <ModalQuiz open={modalQuiz.open} setModal={setModalQuiz} questions={row.quiz?.questions}></ModalQuiz>
                                            <ModalImage open={modalImage.open} setModal={setModalImage} customerChallengeId={row.customerChallengeId}></ModalImage>
                                            <TableCell component="th" scope="row">{index + 1}</TableCell>
                                            <TableCell>
                                                <Avatar
                                                    className='d-inline-grid'
                                                    alt={row.name}
                                                    src={`/static/images/avatar/${index + 1}.jpg`}
                                                /> {row.name}
                                            </TableCell>
                                            <TableCell>
                                                {index === 0 && <EmojiEventsIcon className='first-icon'></EmojiEventsIcon>}
                                                {row.points} Points
                                            </TableCell>
                                            <TableCell>
                                                {row.status == 'completed' &&
                                                    <Button className={'btn-blue-s me-1'} onClick={handleModalQuiz}>View</Button>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {row.status == 'completed' &&
                                                    <Button className={'btn-blue-s me-1'} onClick={()=>handleModalImage(row)}>View</Button>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            {bests.map((value, index) => {
                                const labelId = `checkbox-list-secondary-label-${index}`;
                                return (
                                    <ListItem
                                        key={index}
                                        secondaryAction={
                                            <>
                                                {index === 0 && <EmojiEventsIcon className='first-icon'></EmojiEventsIcon>}
                                                <span className='points-ranking'>{value.points} Points</span>
                                            </>
                                        }
                                        disablePadding >
                                        <ListItemButton>
                                            <span className='number-list'>{index + 1}. </span>
                                            <ListItemAvatar>
                                                <Avatar
                                                    alt={value.name}
                                                    src={`/static/images/avatar/${index + 1}.jpg`}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText id={labelId} primary={value.name} />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List> */}
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='container-card card-content'>
                        <h2>Award</h2>
                        <div className='col-md-12 text-center'>
                            <Button variant="contained" onClick={handleModalWinner}>Add Winner</Button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ProgressChallenge;
