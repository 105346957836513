export const endpoint = {
    numberPlans: 'panel/numberPlans',
    panelCustomer: 'panel/customers',
    bagesAll: 'badges/getAll',
    bagedById: 'badges/badgesById',
    badgeUpsert:  'badges/upsert',
    recommedantionsList: 'actionplansettings/recommedationsList',
    ChallengeList:'challenge/All',
    ChallengeSave:'challenge/',
    ChallengeClone: 'challenge/clone',
    ChallengeDelete: 'challenge/delete',
    ChallengeUpdate:'challenge/update',
    ChallengeById:'challenge/',
    ChallengeByCustomerId:'challenge/Customer/',
    ActionPlanSettingsCategories: 'actionplansettings/categoriesList',
    recommedantionSave: 'actionplansettings/save',
    recommedantionUpdate: 'actionplansettings/update',
    recommedantionGetById: 'actionplansettings/byId',
    getCustomerChallengeCustom: 'challenge/customerChallengeCustom/',
    updateCustomerChallengePosition: 'challenge/updateCustomerChallengePosition',
    product:'product/',
    productUpdate: 'product/update/',
    productBulkStatus: 'product/bulk-status/',
    productPicture:'product/save-image',
    getCustomerImages: 'challenge/customer/images/',
    smartDevice: 'smart-devices/',
    smartDeviceUpdate: 'smart-devices/update',
    customerSmartDevice: 'smart-devices/customer',
    customerSmartDeviceByCustomer: 'smart-devices/byCustomer/',
    recommedantionList: 'actionplansettings/recommedationsList',
    challengeRecommendation: 'challenge/challengeRecommendation',
}