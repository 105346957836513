
import { panelApiInstance } from './../../api';
import { endpoint } from './../../api.endpoints';


export const getChallengesList = () => {
  return panelApiInstance.get(endpoint.ChallengeList)
    .then((response) => {
      return (response) ? response.data : [];
    });
}

export const getChallengeById = (id) => {
  return panelApiInstance.get(`${endpoint.ChallengeById}${id}`)
    .then((response) => {
      return (response) ? response.data : [];
    });
}

export const getChallengeByCustomerId = (id) => {
  return panelApiInstance.get(`${endpoint.ChallengeByCustomerId}${id}`)
    .then((response) => {
      return (response) ? response.data : [];
    });
}

export const saveChallenge = (model) => {
  return panelApiInstance.post(endpoint.ChallengeSave, model)
    .then((response) => {
      return (response) ? response.data : null;
    });
}

export const cloneChallenge = (id) => {
  return panelApiInstance.post(`${endpoint.ChallengeClone}/${id}`, {})
    .then((response) => {
      return (response) ? response.data : null;
    });
}

export const deleteChallenge = (id) => {
  return panelApiInstance.post(`${endpoint.ChallengeDelete}/${id}`, {})
    .then((response) => {
      return (response) ? response.data : null;
    });
}

export const updateChallenge = (model) => {
  return panelApiInstance.post(endpoint.ChallengeUpdate, model)
    .then((response) => {
      return (response) ? response.data : null;
    });
}


export const getCustomerChallengeCustom = (id) => {
  return panelApiInstance.get(`${endpoint.getCustomerChallengeCustom}${id}`)
    .then((response) => {
      return (response) ? response.data : [];
    });
}

export const updatePositions = (model) => {
  return panelApiInstance.post(endpoint.updateCustomerChallengePosition, model)
    .then((response) => {
      return (response) ? response.data : [];
    });
}


export const getCustomerChallengeImages = (customerChalengeId) => {
  return panelApiInstance.get(`${endpoint.getCustomerImages}${customerChalengeId}`)
    .then((response) => {
      return (response) ? response.data : [];
    });
}

export const getRecommendationsList = () => {
  return panelApiInstance.get(`${endpoint.recommedantionList}`)
    .then((response) => {
      return (response) ? response.data : [];
    });
}

export const saveChallengeRecommendation = (model) => {
  return panelApiInstance.post(`${endpoint.challengeRecommendation}`, model)
    .then((response) => {
      return (response) ? response.data : null;
    });
}