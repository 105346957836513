/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect, useRef } from 'react';
import { Button, MenuItem, TextField, FormControl, Typography, Select, InputLabel, Stack } from '@mui/material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { getBadgeList } from '../../components/badges/badgesBase';
import { getCategoriesList, updateRecommendation, getRecommendationById } from '../../components/Recommendations/recommendationsBase';
import { useNavigate, useParams } from "react-router-dom";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { ePage } from '../../App';
import { getProductList } from '../../components/Products/ProductBase';
import { AntSwitch } from '../../components/Shared/AntSwitch';

const productBase = {
    amount: '',
    cost: '',
    description: "",
    descriptionRebate: "",
    disclaimer: "",
    dollarAmount: '',
    eligibility: "",
    energyReduction: '',
    equipmentLifeExpetacy: '',
    id: "",
    maximumAmmountCredited: '',
    name: "",
    onlyBehavior: "",
    percentageProjectCost: '',
    qrCode: "",
    status: true,
    usCimateZone: ""
}

function EditRecommendation(props) {
    const formRef = useRef();

    const [model, setModel] = useState({
        badge: '', badge_group: '', category: '', category_description: '', completion_verification: true, complexity: 0,
        cost_level: 0, cost_level_criteria: false, education: '', effort: '', home_system: '', id: '', impact: '', installation: true, installation_verification: false, lagged_days: 0, lang: '', marketplace: false, _new: true, points_completion: 0, points_intention: 0, product_id: '',
        provider: false, purchase_verification: false, pure_behavoir: false, recommendation: '', recurrent: false, rule: '', saving: '',
        savings_potential: '', savings_potential_type: '', score: 0, season: '', sideeffect: '', status: 'Active'
    });
    const [badges, setBadges] = useState([]);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState(productBase);
    const [activeProduct, setActiveProduct] = useState(false);
    const navigate = useNavigate();
    let { id } = useParams();

    useEffect(() => {
        getCategories();
        getBadges();
        getProducts();
        getRecommendation(id);
    }, []);

    const getRecommendation = (id) => {
        props.setIsLoading(true);
        getRecommendationById(id)
            .then(resp => {
                console.log(resp);
                setModel(resp);
                /*Object.keys(resp).forEach(key =>{
                    if (typeof (resp[key]) == 'boolean') {
                        resp[key] = (new Boolean(resp[key])).toString()
                    }
                });*/
            })
            .catch(console.log)
            .finally(() => props.setIsLoading(false));
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setModel(prevState => ({
            ...prevState, [name]: value
        }));

        if (name === 'product_id' && value) {
            const found = products.find(f => f.id === value);
            if (found) {
                setProduct(found);
            }
        }

        if (name === 'marketplace') {
            if (value === 'true') {
                setActiveProduct(true);
            } else {
                setModel(prevState => ({
                    ...prevState, ['product_id']: ''
                }));
                setProduct(productBase);
                setActiveProduct(false);

            }
        }
    }

    const getBadges = () => {
        getBadgeList()
            .then(resp => {
                setBadges(resp ?? []);
            })
            .catch(console.log)
    }

    const getCategories = () => {
        getCategoriesList()
            .then(resp => {
                setCategories(resp ?? []);
            })
            .catch(console.log)
    }

    const getProducts = () => {
        getProductList()
            .then(data => {
                if (data) {
                    data.unshift({
                        id: '',
                        description: 'select'
                    });
                    setProducts(data);
                }
            }).catch(console.log)
            .finally(() => props.setIsLoading(false));
    }

    const handleSubmit = () => {
        props.setIsLoading(true);
        updateRecommendation(model)
            .then(() => {
                Notify.success('recommendation saved successfully');
                navigate(ePage.recommendation);
            }).catch(() => Notify.failure('Error saving recommendation'))
            .finally(() => props.setIsLoading(false))
    };

    const handleChangeStatus = (event)=>{
        setModel(prevState => ({
            ...prevState, ['status']: event.target.checked ? 'Active' : 'Inactive'
        }));
    }

    return (
        <div className='container mt-5'>
            <h1 className='text-center'>ADD RECOMMENDATION</h1>
            <div className='row mt-3 justify-content-md-center'>
                <div className='col-md-12'>
                    <ValidatorForm ref={formRef} onSubmit={handleSubmit} onError={errors => console.log(errors)}>
                        <div className='container-card card-content'>
                            <div className="row justify-content-md-center mb-4">
                                <div className='col-2'>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography>Disable</Typography>
                                        <AntSwitch checked={model.status === 'Active'}
                                            onChange={handleChangeStatus} />
                                        <Typography>Enable</Typography>
                                    </Stack>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="row justify-content-md-center mb-4">
                                        <div className="col-md-12">
                                            <h6 className='text-center strong'>Recommendation description</h6>
                                            <TextField fullWidth size="small" name='category_description' value={model.category_description} className="text-terms" multiline rows={2} label="" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center mb-4">
                                        <div className="col-md-9">
                                            <h6 className='text-center strong'>Education / telling signs</h6>
                                            <TextField fullWidth size="small" name='education' value={model.education ?? ''} onChange={handleChange} className="text-terms" multiline rows={2} label="text / Links" />
                                        </div>
                                        <div className='col-md-3'>
                                            <h6 className='text-center strong'>&nbsp;</h6>
                                            <TextField fullWidth size="small" style={{ display: 'none' }} className="text-terms" multiline rows={2} label="Picture" />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h6 className='text-center strong'>Settings</h6>
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center mb-2">
                                        <div className="col-md-6">
                                            <FormControl size="small" fullWidth>
                                                <InputLabel id="category">Category</InputLabel>
                                                <Select labelId="category" required name="category" value={model.category??''} onChange={handleChange} label={'Category'}>
                                                    {categories.map((item, index) => (
                                                        <MenuItem key={index} value={item.value}>{item.key}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-6">
                                            <TextValidator autoComplete="nope" fullWidth size="small" name="points_intention" value={model.points_intention} onChange={handleChange} label={'Points'} validators={['required']} errorMessages={'Name requerido'} />
                                        </div>
                                    </div>
                                    <div className="row mt-2 justify-content-md-center">
                                        <div className="col-md-6">
                                            <FormControl size="small" fullWidth>
                                                <InputLabel id="badge">Badge</InputLabel>
                                                <Select labelId="badge" required name="badge" value={model.badge?? ''} onChange={handleChange} label={'Badge'}>
                                                    {badges.map((item, index) => (
                                                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-6">
                                            <FormControl size="small" fullWidth>
                                                <InputLabel id="season">Season</InputLabel>
                                                <Select labelId="season" required name="season" value={model.season} onChange={handleChange} label={'Season'}>
                                                    <MenuItem value="No">No</MenuItem>
                                                    <MenuItem value="Winter">Winter</MenuItem>
                                                    <MenuItem value="Summer">Summer</MenuItem>
                                                    <MenuItem value="Fall">Fall</MenuItem>
                                                    <MenuItem value="Spring">Spring</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="row mt-2 justify-content-md-center">
                                        <div className="col-md-6">
                                            <FormControl size='small' fullWidth>
                                                <InputLabel>Recurrent</InputLabel>
                                                <Select labelId="recurrent" required name="recurrent" value={model.recurrent} onChange={handleChange} label={'Recurrent'}>
                                                    <MenuItem value="true">Yes</MenuItem>
                                                    <MenuItem value="false">No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-6">
                                            <TextValidator autoComplete="nope" fullWidth size="small" name="lagged_days" value={model.lagged_days} onChange={handleChange} label={'Lagged Days'} validators={['required']} errorMessages={'lagged days required'} />
                                        </div>
                                    </div>
                                    <div className="row mt-4 justify-content-md-center">
                                        <h6 className='text-center strong'>Fullfillment</h6>
                                    </div>
                                    <div className="row mt-2 justify-content-md-center">
                                        <div className="col-md-6">
                                            <FormControl size='small' fullWidth>
                                                <InputLabel>marketplace</InputLabel>
                                                <Select labelId="marketplace" required name="marketplace" value={model.marketplace} onChange={handleChange} label={'marketplace'}>
                                                    <MenuItem value="true">Yes</MenuItem>
                                                    <MenuItem value="false">No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-6">
                                            {/* <FormControl size='small' fullWidth>
                                                <InputLabel>Completion verification</InputLabel>
                                                <Select labelId="completion_verification" name="completion_verification" value={model.completion_verification} onChange={handleChange} label={'completion_verification'}>
                                                    <MenuItem value="true">Yes</MenuItem>
                                                    <MenuItem value="false">No</MenuItem>
                                                </Select>
                                            </FormControl> */}
                                            <FormControl size='small' fullWidth>
                                                <InputLabel>Products</InputLabel>
                                                <Select labelId="product_id" name="product_id" disabled={!activeProduct} value={model.product_id ?? ''} onChange={handleChange} label={'product_id'}>
                                                    {products.map((item, index) => (
                                                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {activeProduct && <small><a href="#">Add another product</a></small>}
                                        </div>
                                    </div>
                                    <div className="row mt-2 justify-content-md-center">
                                        <div className="col-md-6">
                                            <FormControl size='small' fullWidth>
                                                <InputLabel>Provider</InputLabel>
                                                <Select labelId="Provider" required name="provider" value={model.provider} onChange={handleChange} label={'Provider'}>
                                                    <MenuItem value="true">Yes</MenuItem>
                                                    <MenuItem value="false">No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-6">
                                            {/* <TextValidator autoComplete="nope" fullWidth size="small" name="purchase_verification" value={model.purchase_verification} onChange={handleChange} label='Purchase verification' validators={['required']} errorMessages={'Name requerido'} /> */}
                                            <FormControl size='small' fullWidth>
                                                <InputLabel>Purchase verification</InputLabel>
                                                <Select labelId="purchase_verification" name="purchase_verification" value={model.purchase_verification} onChange={handleChange} label={'purchase verification'}>
                                                    <MenuItem value="true">Yes</MenuItem>
                                                    <MenuItem value="false">No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="row mt-2 justify-content-md-center">
                                        <div className="col-md-6">
                                            <FormControl size='small' fullWidth>
                                                <InputLabel>Installation</InputLabel>
                                                <Select labelId="Installation" required name="installation" value={model.installation} onChange={handleChange} label={'Installation'}>
                                                    <MenuItem value="true">Yes</MenuItem>
                                                    <MenuItem value="false">No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-6">
                                            <FormControl size='small' fullWidth>
                                                <InputLabel>Installation verification</InputLabel>
                                                <Select labelId="installation_verification" required name="installation_verification" value={model.installation_verification} onChange={handleChange} label={'installation_verification'}>
                                                    <MenuItem value="true">Yes</MenuItem>
                                                    <MenuItem value="false">No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h6 className='text-center strong'>Costs</h6>
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center mb-4">
                                        <div className="col-md-6">
                                            <TextValidator autoComplete="nope" fullWidth size="small" name="cost" value={product?.cost} label='Cost ($)' />
                                        </div>
                                        <div className="col-md-6">
                                            <TextValidator autoComplete="nope" fullWidth size="small" name="onlyBehavior" value={product?.onlyBehavior} label='Only behavior change' />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row mt-4'>
                                        <div className='col-12'>
                                            <h6 className='text-center strong'>Savings</h6>
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center mb-2">
                                        <div className="col-md-6">
                                            <TextValidator autoComplete="nope" fullWidth size="small" name="energyReduction" value={product.energyReduction} label='Energy reduction %' />
                                        </div>
                                        <div className="col-md-6">
                                            <TextValidator autoComplete="nope" fullWidth size="small" name="dollarAmount" value={product.dollarAmount} label='Dollar ammount' />
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center mb-5">
                                        <div className="col-md-6">
                                            <TextValidator autoComplete="nope" fullWidth size="small" name="equipmentLifeExpetacy" value={product.equipmentLifeExpetacy} label='Equipment life expectacy' />
                                        </div>
                                        <div className="col-md-6">
                                            <TextValidator autoComplete="nope" fullWidth size="small" name="usCimateZone" value={product.usCimateZone} label='US climate zone' />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row mt-4'>
                                        <div className='col-12'>
                                            <h6 className='text-center strong'>Tax Credits</h6>
                                        </div>
                                        <div className="row justify-content-md-center mb-2">
                                            <div className="col-md-6">
                                                <TextValidator autoComplete="nope" fullWidth size="small" name="percentageProjectCost" value={product.percentageProjectCost} label='% of project cost' />
                                            </div>
                                            <div className="col-md-6">
                                                <TextValidator autoComplete="nope" fullWidth size="small" name="maximumAmmountCredited" value={product.maximumAmmountCredited} label='maximum ammount credited ($)' />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row mt-4'>
                                        <div className='col-12'>
                                            <h6 className='text-center strong'>Rebates / Vouchers / Incentives</h6>
                                        </div>
                                        <div className="row justify-content-md-center mb-2">
                                            <div className="col-md-12">
                                                <TextValidator autoComplete="nope" fullWidth size="small" name="description" value={product.description} label='Description' />
                                            </div>
                                        </div>
                                        <div className="row justify-content-md-center mb-2">
                                            <div className="col-md-6">
                                                <TextValidator autoComplete="nope" fullWidth size="small" name="Ammount" value={product.amount} label='Ammount $' />
                                            </div>
                                            <div className="col-md-6">
                                                <TextValidator autoComplete="nope" fullWidth size="small" name="QR_code" value={product.qrCode} label='QR code / Link' />
                                            </div>
                                        </div>
                                        <div className="row justify-content-md-center mb-2">
                                            <div className="col-md-6">
                                                <TextValidator autoComplete="nope" fullWidth size="small" name="Eligibility" value={product.eligibility} label='Eligibility' />
                                            </div>
                                            <div className="col-md-6">
                                                <TextValidator autoComplete="nope" fullWidth size="small" name="Disclaimer" value={product.disclaimer} label='Disclaimer' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-4 justify-content-md-center'>
                                <div className='col-md-12 text-center'>
                                    <Button variant="contained" type='submit'>Save</Button>
                                </div>
                            </div>
                        </div>
                    </ValidatorForm>
                </div>
            </div>
        </div>
    );
}

export default EditRecommendation;
