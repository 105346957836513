import axios from 'axios';
import { getParams } from './utils';
import { getStorage, eStorage } from './services/StorageService';

const requestInterceptor = (config) => {
  const token = getStorage(eStorage.bearer);
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  config.headers['Content-Type'] = 'application/json';
  return config
}

const requestInterceptorError = (error) => {
  if (error && error.response && (error.response.status === 401)) {
    localStorage.clear();
    window.location.pathname = '/login';
  }
  throw error;
}

const responseInterceptor = (response) => {
  return response
}

const getPanelAxios = () => {
  const instance = axios.create({
    baseURL: getParams().DATASERVICES_URL,
    timeout: 30000
  });
  instance.interceptors.request.use(requestInterceptor,);
  instance.interceptors.response.use(responseInterceptor, requestInterceptorError);
  return instance;
};

const getOrchestratorAxios = () => {
  const instance = axios.create({
    baseURL: getParams().ORCHESTRATOR_URL,
    timeout: 120000,
  });
  instance.interceptors.request.use(requestInterceptor,);
  instance.interceptors.response.use(responseInterceptor, requestInterceptorError);
  return instance;
};


const getCalcEngineAxios = () => {
  const instance = axios.create({
    baseURL: getParams().CALCENGINE_URL,
    timeout: 100000
  });
  instance.interceptors.request.use(requestInterceptor,);
  instance.interceptors.response.use(responseInterceptor, requestInterceptorError);
  return instance;
};

export const panelApiInstance = getPanelAxios();
export const calcEngineApiInstance = getCalcEngineAxios();
export const orchestratorApiInstance = getOrchestratorAxios();