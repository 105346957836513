/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect, useRef } from 'react';
import { Button, Switch, RadioGroup, FormControlLabel, Radio, FormHelperText, MenuItem, TextField, styled, FormControl, Typography, Select, InputLabel, Stack } from '@mui/material';
import ImageChallenge from '../../assets/challenge.png';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ModalQuestion from './ModalQuestion';
import ModalImage from '../../shared/ModalImage';
import { getStorageJSON, eStorage } from './../../services/StorageService';
import { updateChallenge, getChallengeById } from './../../components/Challenge/challengeBase';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { useNavigate, useSearchParams } from "react-router-dom";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { getParams } from './../../utils';


function EditChallenge(props) {
    const formRef = useRef();
    const end = new Date();
    const start = new Date();
    start.setDate(start.getDate() - 1);
    const [model, setModel] = useState({ id: '', name: '', startDate: start, endDate: end, type: '', challengeDescription: '', customerId: '', pointsEarned: '', pointsMax: '', prizeDescription: '', status: true, recommendation: '', education: '', important: '', participate: '', winners: '', picture: '', imageRelation: 0, verificationImage: false });
    const [questions, setQuestions] = useState([]);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [modalImageOpen, setModalImageOpen] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);
    const [currentImage, setCurrentImage] = useState(ImageChallenge);
    const fileElement = useRef(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [modal, setModal] = useState({ open: false });
    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
                transform: 'translateX(12px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 12,
            height: 12,
            borderRadius: 6,
            transition: theme.transitions.create(['width'], {
                duration: 200,
            }),
        },
        '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor:
                theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
            boxSizing: 'border-box',
        },
    }));
    const [challengeId] = useState(searchParams.get("id"));

    useEffect(() => {
        props.setIsLoading(true);
        const user = getStorageJSON(eStorage.user);
        if (user) {
            model.customerId = user.customerId;
        }
        let id = searchParams.get("id");
        getChallengeById(id)
            .then(resp => {
                if (resp) {
                    let data = resp[0];
                    data.startDate = new Date(data.startDate);
                    data.endDate = new Date(data.endDate);
                    console.log(data);
                    setModel(data);
                    setQuestions(data?.quiz?.questions);
                    generateImageUrl(data);
                }
            })
            .catch(console.log)
            .finally(() => props.setIsLoading(false))
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setModel(prevState => ({
            ...prevState, [name]: value
        }));
    }

    const handleChangeSwitch = (event) => {
        const { name, checked } = event.target;
        setModel(prevState => ({
            ...prevState, [name]: checked
        }));
    }


    const generateImageUrl = (model) => {
        if (model.picture) {
            const url = `${getParams().DATASERVICES_URL}/challenge/image/${model.picture}`;
            setCurrentImage(url);
        }
    }

    const handleSubmit = () => {
        props.setIsLoading(true);
        let request = model;
        delete request['recommendation'];
        request.picture = '';
        if (croppedImage) {
            request.picture = croppedImage.split(',')[1];
        }
        request.quiz = { questions: questions };
        updateChallenge(request).then(() => {
            Notify.success('saved');
            navigate('/gamification');
        }).catch(() => Notify.failure('Error saving data'))
            .finally(() => props.setIsLoading(false));
    };

    const handleChangeStartDate = (newValue) => {
        setModel(prevState => ({
            ...prevState, ['startDate']: newValue
        }));
    };

    const handleChangeEndDate = (newValue) => {
        setModel(prevState => ({
            ...prevState, ['endDate']: newValue
        }));
    };

    const handleModal = (event) => {
        setModal({ open: true });
    }

    const handleAddPicture = () => {
        fileElement.current.click();
    }

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const totalSizeMB = file.size / Math.pow(2024, 2)
            if (totalSizeMB > 2.024) {
                Notify.failure('File is too big!', { position: 'center-bottom', });
            } else {
                let imageDataUrl = await readFile(file)
                setImageSrc(imageDataUrl);
                setModalImageOpen(true);
            }
        }
    }

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    return (
        <div className='container-fluid'>
            <ModalQuestion setQuestions={setQuestions} questions={questions} open={modal.open} setModal={setModal} modal={modal}></ModalQuestion>
            <ModalImage open={modalImageOpen} setModalOpen={setModalImageOpen} croppedImage={croppedImage} setCroppedImage={setCroppedImage} imageSrc={imageSrc} />
            <h1 className='text-center'>EDIT CHALLENGE</h1>

            <div className='row mt-3 justify-content-md-center'>
                <div className='col-md-6'>
                    <div className='container-card card-content'>
                        <h2>CHALLENGE DETAIL</h2>
                        <div className='row'>
                            <div className='col-md-3 text-center'>
                                {!croppedImage && <img className="img-fluid challenge" src={currentImage} alt="challenge" onError={(e) => { e.target.src = ImageChallenge }} />}
                                {croppedImage && <img className="img-fluid challenge" src={croppedImage} alt="challenge" onError={(e) => { e.target.src = ImageChallenge }} />}
                                <input type="file" style={{ display: 'none' }} onChange={onFileChange} accept="image/*" ref={fileElement} />
                                <Button variant="contained" endIcon={<PhotoCameraIcon />} onClick={handleAddPicture}>Add</Button>
                            </div>
                            <div className='col-md-8'>
                                <ValidatorForm ref={formRef} onSubmit={handleSubmit} onError={errors => console.log(errors)}>
                                    <div className="row justify-content-md-center">
                                        <div className="col-md-12">
                                            <TextValidator autoComplete="nope" value={model.name ?? ''} fullWidth size="small" name="name" onChange={handleChange} label={'Name'} validators={['required']} />
                                        </div>
                                    </div>
                                    <div className="row mt-2 justify-content-md-center">
                                        <div className="col-md-6">
                                            <FormControl size="small" fullWidth>
                                                <InputLabel id="type">Type</InputLabel>
                                                <Select labelId="type" required name="type" value={model.type} onChange={handleChange} label={'Type'}>
                                                    <MenuItem value="recommendation">Recommendations</MenuItem>
                                                    <MenuItem value="photographs">Photographs</MenuItem>
                                                    <MenuItem value="quiz">Quiz</MenuItem>
                                                    <MenuItem value="smartdevices">Smart Devices</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-6">
                                            <TextValidator type='number' autoComplete="nope" fullWidth size="small" name="imageRelation" value={model.imageRelation} onChange={handleChange} label={'Image Relation'} validators={['required']} />
                                        </div>
                                    </div>
                                    {model.type === 'recommendation' &&
                                        <div className="row mt-2 justify-content-md-center">
                                            <div className="col-md-12">
                                                <FormControl size="small" fullWidth>
                                                    <InputLabel id="recommendation">Select Recommendations</InputLabel>
                                                    <Select labelId="recommendation" required name="recommendation" onChange={handleChange} label={'Select Recommendation'}>
                                                        <MenuItem value="all">Upgrade exterior and interior to LED for all high use bulbs</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    }
                                    <div className="row mt-2 justify-content-md-center">
                                        <div className="col-md-4">
                                            <FormHelperText>Verification Image Required</FormHelperText>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography>No</Typography>
                                                <AntSwitch checked={model.verificationImage} name='verificationImage' onChange={handleChangeSwitch} inputProps={{ 'aria-label': 'ant design' }} />
                                                <Typography>Yes</Typography>
                                            </Stack>
                                        </div>
                                        <div className="col-md-4">
                                            <TextValidator type='number' autoComplete="nope" fullWidth size="small" name="pointsEarned" value={model.pointsEarned} onChange={handleChange} label={'Points Earned'} validators={['required']} />
                                        </div>
                                        <div className="col-md-4">
                                            <TextValidator type='number' autoComplete="nope" fullWidth size="small" name="pointsMax" value={model.pointsMax} onChange={handleChange} label={'Max Points'} validators={['required']} />
                                        </div>
                                    </div>
                                    <div className="row mt-2 justify-content-md-center">
                                        <div className="col-md-6">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    label="Start Date"
                                                    value={model.startDate}
                                                    onChange={handleChangeStartDate}
                                                    renderInput={(params) => <TextField size="small" {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <div className="col-md-6">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    label="End Date"
                                                    value={model.endDate}
                                                    onChange={handleChangeEndDate}
                                                    renderInput={(params) => <TextField size="small" {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                    <div className="row mt-2 justify-content-md-center">
                                        <div className="col-md-12">
                                            <TextField fullWidth size="small" className="text-terms" name='prizeDescription' value={model.prizeDescription ?? ''} onChange={handleChange} multiline rows={3} label={'Prize Description'} />
                                        </div>
                                    </div>
                                    <div className="row mt-2 justify-content-md-center">
                                        <div className="col-md-12">
                                            <TextField fullWidth size="small" name='challengeDescription' className="text-terms" value={model.challengeDescription ?? ''} onChange={handleChange} multiline rows={3} label={'Description'} />
                                        </div>
                                    </div>
                                    <div className="row mt-2 justify-content-md-center">
                                        <div className="col-md-12">
                                            <TextField fullWidth size="small" name='education' className="text-terms" value={model.education ?? ''} onChange={handleChange} multiline rows={2} label={'Education'} />
                                        </div>
                                    </div>
                                    <div className="row mt-2 justify-content-md-center">
                                        <div className="col-md-12">
                                            <TextField fullWidth size="small" name='important' className="text-terms" value={model.important ?? ''} onChange={handleChange} multiline rows={2} label={'Important'} />
                                        </div>
                                    </div>
                                    <div className="row mt-2 justify-content-md-center">
                                        <div className="col-md-12">
                                            <TextField fullWidth size="small" name='participate' className="text-terms" value={model.participate ?? ''} onChange={handleChange} multiline rows={2} label={'Participate'} />
                                        </div>
                                    </div>
                                    <div className="row mt-2 justify-content-md-center">
                                        <div className="col-md-12">
                                            <TextField fullWidth size="small" name='winners' className="text-terms" value={model.winners ?? ''} onChange={handleChange} multiline rows={2} label={'Winners'} />
                                        </div>
                                    </div>
                                    <div className='row mt-2 justify-content-md-center'>
                                        <div className='col-md-12 text-center'>
                                            <Button variant="contained" type='submit'>Save</Button>
                                        </div>
                                    </div>
                                </ValidatorForm>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    {model.type === 'quiz' && <>
                        <div className='row justify-content-md-center'>
                            <div className='container-card card-content'>
                                <h2>SET UP YOUR QUIZ</h2>
                                <div className='col-md-12 text-center'>
                                    <Button variant="contained" onClick={handleModal}>Add Question</Button>
                                </div>


                                {questions?.map((value, index) => {
                                    return (<div key={index}>
                                        <div className='row justify-content-md-center mt-4'>
                                            <div className='col-md-12'>
                                                {index + 1}. {value.questionValue}
                                            </div>
                                        </div>
                                        <div className='row justify-content-md-center'>
                                            <div className='col-md-12 text-center'>
                                                {value.typeValue === 'short' &&
                                                    < TextField label="Response" variant="outlined" />
                                                }
                                                {value.typeValue === 'paragraph' &&
                                                    <TextField multiline rows={3} label="Response" variant="outlined" />
                                                }
                                                {value.typeValue === 'multiple' &&
                                                    <FormControl>
                                                        <RadioGroup
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            defaultValue="female"
                                                            name="radio-buttons-group"
                                                        >
                                                            <FormControlLabel value="1" control={<Radio />} label={value.options[0]?.value} />
                                                            <FormControlLabel value="2" control={<Radio />} label={value.options[1]?.value} />
                                                            {value.options[2]?.value &&
                                                                <FormControlLabel value="" control={<Radio />} label={value.options[2]?.value} />}
                                                        </RadioGroup>
                                                    </FormControl>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })}
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </div>
    );
}

export default EditChallenge;
