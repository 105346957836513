
import { panelApiInstance } from './../../api';
import { endpoint } from './../../api.endpoints';


export const getRecommendationsList = () => {
  return panelApiInstance.get(endpoint.recommedantionsList)
    .then((response) => {
      return (response) ? response.data : [];
    });
}

export const getCategoriesList = () => {
  return panelApiInstance.get(endpoint.ActionPlanSettingsCategories)
    .then((response) => {
      return (response) ? response.data : [];
    });
}

export const getRecommendationById = (id) => {
  return panelApiInstance.get(`${endpoint.recommedantionGetById}/${id}`)
    .then((response) => {
      return (response) ? response.data : {};
    });
}

export const saveRecommendation = (model) => {
  return panelApiInstance.post(endpoint.recommedantionSave, model)
    .then((response) => {
      return (response) ? response.data : null;
    });
}

export const updateRecommendation = (model) => {
  return panelApiInstance.post(endpoint.recommedantionUpdate, model)
    .then((response) => {
      return (response) ? response.data : null;
    });
}
