// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-avatar-crop-container {
  position: relative;
  width: 100%;
  height: 300px;
  background: #333;
}

.modal-avatar-controls {
 /* transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;*/
  margin-top: 20px;
}

.modal-avatar-slider {
  padding: 22px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/shared/ModalImage.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,gBAAgB;AAClB;;AAEA;CACC;;;uBAGsB;EACrB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".modal-avatar-crop-container {\n  position: relative;\n  width: 100%;\n  height: 300px;\n  background: #333;\n}\n\n.modal-avatar-controls {\n /* transform: translateX(-50%);\n  height: 80px;\n  display: flex;\n  align-items: center;*/\n  margin-top: 20px;\n}\n\n.modal-avatar-slider {\n  padding: 22px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
