/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import ImageChallenge from '../../assets/trophy.png';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { getList, ImageBaseUrl } from './../../components/SmartDevices/SmartDeviceBase';
import { ePage } from '../../App';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';

export default function  SmartDeviceIndex(props) {

  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const columnsDg = [
    { field: "index", headerName: "#", width: 50, },
    {
      field: "",
      headerName: "Image",
      editable: false,
      width: 200,
      height: 250,
      renderCell: (params) => {
        return (
          <img className="img-fluid challenge" style={{ height: '100%' }} src={`${ImageBaseUrl}/${params.row.photo}`} alt="smart device" onError={(e) => { e.target.src = ImageChallenge }} />
        );
      }
    },
    { field: "name", headerName: "Name", width: 150, },
    { field: "description", headerName: "Description", width: 400, },
    {
      field: "action",
      headerName: "Action",
      editable: false,
      renderCell: (params) => {
        return (
          <IconButton aria-label="edit" onClick={() => redirect(params.row.id)} size="large">
            <EditIcon fontSize="inherit" />
          </IconButton>
        );
      }
    },
  ];

  useEffect(() => {
    getApiList();
  }, []);


  const getApiList = () => {
    props.setIsLoading(true);
    getList()
      .then(data => {
        const mapped = data.map((m, inx) => {
          m.index = inx + 1;
          return m;
        })
        setRows(mapped);
      }).catch(console.log)
      .finally(() => props.setIsLoading(false));
  }

  const handleCreate = () => {
    navigate(ePage.smartDeviceAdd);
  }

  const redirect = (id) => {
    navigate(`${ePage.smartDeviceEdit}/${id}`);
  }

  return <div className='container'>
    <h1 className='text-center'>Smart Device</h1>
    <div className='row justify-content-md-center'>
      <div className='col-md-12 text-center'>
        <Button variant="contained" endIcon={<AddIcon />} onClick={handleCreate}>New smart device</Button>
      </div>
    </div>
    <hr />
    <div className='row mt-3'>
      <div className='col-md-12'>
        <DataGrid rows={rows} columns={columnsDg}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
        />
      </div>
    </div>
  </div>
}