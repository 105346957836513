import { React, useState, useEffect, useRef } from 'react';
import { Button, TextField } from '@mui/material';
import ImageChallenge from '../../assets/challenge.png';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { upsertBadge } from './../../components/badges/badgesBase';
import { useNavigate } from "react-router-dom";
import { ePage } from './../../App';

function AddBadge(props) {

    const fileElement = useRef(null);
    const formRef = useRef();
    const [model, setModel] = useState({ id: '', type: '', title: { en: '', es: '' }, name: '', icon: '', goal: '', description: { en: '', es: '' } });
    const [titleEn, setTitleEn] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
    }, []);

    const readFile = async (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file);
        })
    }

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            var totalSizeMB = file.size / Math.pow(1024, 2)
            if (totalSizeMB > 1.024) {
                Notify.failure('File is too big!', { position: 'center-bottom', });
            } else {
                let imageDataUrl = await readFile(file)
                setModel(prevState => ({
                    ...prevState, ['icon']: imageDataUrl
                }));
            }
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'description' || name === 'title') {
            const temp = model;
            temp[name]['en'] = value;
            setModel(temp);
            if (name === 'title') {
                setTitleEn(value);
            }

            if (name === 'description') {
                setDescriptionEn(value);
            }
        } else {
            setModel(prevState => ({
                ...prevState, [name]: value
            }));
        }
    }

    const handleLoad = () => {
        fileElement.current.click();
    }

    const handleSubmit = () => {
        props.setIsLoading(true);
        upsertBadge(model)
            .then(data => {
                Notify.success('badge saved!', { position: 'center-bottom' });
                navigate(ePage.badges);
            }).catch(() => {
                Notify.failure('Error saving badge, please, try later!', { position: 'center-bottom', });
            })
            .finally(() => props.setIsLoading(false));
    };

    return (
        <div className='container'>
            <div className='row mt-3 justify-content-md-center'>
                <div className='col-md-8'>
                    <div className='container-card card-content'>
                        <h2>ADD BADGE</h2>
                        <div className='row'>
                            <div className='col-md-3 text-center'>
                                <img className="img-fluid challenge" src={ImageChallenge} alt="challenge" />
                            </div>
                            <div className='col-md-9'>
                                <ValidatorForm ref={formRef} onSubmit={handleSubmit} onError={errors => console.log(errors)}>
                                    <div className="row justify-content-md-center">
                                        <div className="col-md-12">
                                            <TextValidator autoComplete="nope" fullWidth size="small" name="name" value={model.name} onChange={handleChange} label={'Name'} validators={['required']} errorMessages={'Name requerido'} />
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center mt-3">
                                        <div className="col-md-12">
                                            <TextValidator autoComplete="nope" fullWidth size="small" name="title" value={titleEn} onChange={handleChange} label={'Title'} validators={['required']} errorMessages={'title requerido'} />
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center mt-3">
                                        <div className="col-md-12">
                                            <TextValidator autoComplete="nope" type='number' fullWidth size="small" name="goal" value={model.goal} onChange={handleChange} label={'Goal'} validators={['required']} errorMessages={'goal requerido'} />
                                        </div>
                                    </div>
                                    <div className="row mt-3 justify-content-md-center">
                                        <div className="col-md-12">
                                            <TextField fullWidth size="small" className="text-terms" value={descriptionEn} onChange={handleChange} multiline rows={3} label={'Description'} name="description" />
                                        </div>
                                    </div>
                                    <div className="row mt-2 justify-content-md-center">
                                        <div className="col-md-12">
                                            <Button variant="contained" onClick={handleLoad}>Upload icon</Button>
                                            <input type="file" style={{ display: 'none' }} max-file-size="1024" onChange={onFileChange} accept="image/svg+xml" ref={fileElement} />
                                            {model.icon && <img src={model.icon} alt="icon" />}
                                        </div>
                                    </div>
                                    <div className='row mt-3 justify-content-md-center'>
                                        <div className='col-md-12 text-center'>
                                            <Button variant="contained" type='submit'>Save</Button>
                                        </div>
                                    </div>
                                </ValidatorForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddBadge;
