/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, forwardRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Slide } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { getCustomerChallengeCustom, updatePositions } from '../../components/Challenge/challengeBase';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Container } from './Container';
import { Notify } from 'notiflix/build/notiflix-notify-aio';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalAward(props) {
    const formRef = useRef();
    const [winner, setWinner] = useState([]);
    const [ordenedWinner, setOrdenedWinner] = useState([]);

    useEffect(() => {
        customerChallengeCustomGet();
    }, []);

    const customerChallengeCustomGet = () => {
        getCustomerChallengeCustom(props.challengeId)
            .then(resp => {
                setWinner(resp ?? []);
            }).catch(console.log);
    }

    const handleSubmit = () => {
        if (ordenedWinner && Array.isArray(ordenedWinner)) {
            const model = ordenedWinner.map((m, index) => ({
                customerChallengeId: m.customerChallengeId,
                customerId: m.customerId,
                position: index + 1
            }));
            updatePositions(model)
            .then(resp => {
                Notify.success('Positions saved successfully', { position: 'center-bottom', });
                handleClose(true);
            }).catch(()=>{
                Notify.failure('Error saving positions', { position: 'center-bottom', });
            });
        }
    };

    const handleClose = (reload) => {
        props.setModal({ open: false, reload: reload ?? false });
    };

    return (
        <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'} keepMounted onClose={handleClose}>
            <ValidatorForm ref={formRef} onSubmit={handleSubmit} onError={errors => console.log(errors)}>
                <DialogTitle className='text-center'>Awards</DialogTitle>
                <hr className='m-0' />
                <DialogContent>
                    <div className="row mt-2 justify-content-md-center">
                        <div className="col-md-6">
                            <DndProvider backend={HTML5Backend}>
                                <Container winner={winner} setOrderWinners={setOrdenedWinner} />
                            </DndProvider>
                        </div>
                    </div>
                </DialogContent>
                <hr className='mb-0 mt-2' />
                <DialogActions className='justify-content-md-center'>
                    <Button className='btn-secondary me-1' onClick={handleClose}>Cancelar</Button>
                    <Button variant="contained" type="submit">
                        Guardar
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}
