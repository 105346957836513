import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { panelApiInstance } from "../../api";
import TablePaginationActions from "../../components/TablePaginationActions";

function Order() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [type, setType] = useState("All");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getCustomers();
    }, []);

    function getCustomers() {
        panelApiInstance.get(`order/`)
            .then(({ data }) => {
                setRows(data);
            })
            .catch(error => console.log(error));
    }

    return (
        <div className='container'>
            <h1 className='text-center'>ORDERS</h1>
            <div className='row mt-3'>
                <div className='col-md-12'>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Customer</TableCell>
                                    <TableCell>Product</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Created</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : rows
                                ).map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell>{row.customerName}</TableCell>
                                        <TableCell>{row.productName}</TableCell>
                                        <TableCell>${row.amount?.toLocaleString('en-US', { maximumFractionDigits: 2 })}</TableCell>
                                        <TableCell>{row.created?.split(' ')[0]}</TableCell>
                                        <TableCell>Open</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </div>
            </div>
        </div>
    );
}

export default Order;
