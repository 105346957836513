import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Slide } from '@mui/material';
import { forwardRef } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalQuestionYesNo(props) {

  const handleOk = (event) => {
    if (props.handleOk) {
      props.handleOk({
        event,
        extraData: props.modal?.extraData ?? {}
      });
    }
  }
  const handleCancel = (event) => {
    if (props.handleCancel) {
      props.handleCancel({
        event,
        extraData: props.modal?.extraData ?? {}
      });
    }
  }

  return (
    <Dialog open={props.modal.open ?? false} TransitionComponent={Transition} fullWidth={true} maxWidth={'sm'} keepMounted onClose={handleCancel}>
      <DialogTitle className='text-center'>{props.modal?.title ?? ''}</DialogTitle>
      <hr className='m-0' />
      <DialogContent>
        <div className='row mt-2 justify-content-md-center'>
          <div className='col-md-12'>
            {props.modal?.message ?? ''}
          </div>
        </div>
      </DialogContent>
      <hr className='mb-0 mt-2' />
      <DialogActions className='justify-content-md-center'>
        <div className='col-md-2'>
          <Button variant="contained" onClick={handleCancel}>No</Button>
        </div>
        <div className='col-md-2'>
          <Button variant="contained" onClick={handleOk}>Yes</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
