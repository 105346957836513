/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ePage } from '../../App';
import { useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import { getProductList, updateProduct } from '../../components/Products/ProductBase';
import EditIcon from '@mui/icons-material/Edit';
import { AntSwitch } from '../../components/Shared/AntSwitch';
import ModalQuestionYesNo from './../../shared/ModalQuestionYesNo';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import ImageChallenge from '../../assets/trophy.png';
import { getParams } from './../../utils';
import { DataGrid } from '@mui/x-data-grid';

export default function ProductList(props) {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const columnsDg = [
    { field: "index", headerName: "#", width: 50, },
    {
      field: "",
      headerName: "Image",
      editable: false,
      width: 200,
      height:250,
      renderCell: (params) => {
        return (
          <img className="img-fluid challenge" style={{height:'100%'}} src={`${getParams().DATASERVICES_URL}/product/image/${params.row.picture}`} alt="product" onError={(e) => { e.target.src = ImageChallenge }} />
        );
      }
    },
    { field: "name", headerName: "Name", width: 140, },
    { field: "description", headerName: "Description", width: 350, },
    { field: "costFormat", headerName: "Cost", width: 160, },
    {
      field: "active",
      headerName: "Status",
      editable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            Disable
            <AntSwitch className='ms-1 me-1' checked={params.row.status ?? false} onChange={(event) => changeSwitch(params.row)} />
            Enable
          </Stack>
        );
      }
    },
    {
      field: "action",
      headerName: "Action",
      editable: false,
      renderCell: (params) => {
        return (
          <IconButton aria-label="edit" onClick={() => redirect(params.row.id)} size="large">
            <EditIcon fontSize="inherit" />
          </IconButton>
        );
      }
    },
  ];

  const [modal, setModal] = useState({ open: false, title: '', message: '', extraData: {} });

  useEffect(() => {
    getApiList();
  }, []);


  const getApiList = () => {
    props.setIsLoading(true);
    getProductList()
      .then(data => {
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        const mapped = data.map((m, inx) => {
          m.index = inx+1;
          m.active = '';
          m.costFormat = formatter.format(m.cost);
          return m;
        })
        setRows(mapped);
      }).catch(console.log)
      .finally(() => props.setIsLoading(false));
  }

  const handleCreate = () => {
    navigate(ePage.addProduct);
  }

  const redirect = (id) => {
    navigate(`${ePage.editProduct}?id=${id}`);
  }

  const modalYes = (event) => {
    props.setIsLoading(true);
    console.log(event);
    const product = event.extraData.row;
    product.status = !product.status;
    updateProduct(product)
      .then(() => {
        Notify.success('status changed successfully!');
        getApiList();
      })
      .catch(() => {
        Notify.failure('Error trying to change status');
        props.setIsLoading(false);
      })
    setModal({ open: false });
  }

  const modalNo = (event) => {
    setModal({ open: false });
  }

  const changeSwitch = (row) => {
    setModal({ open: true, title: 'Confirm', message: `Are you sure you want to change status: ${row.name}?`, extraData: { row } });
  }

  return <div className='container'>
    <ModalQuestionYesNo modal={modal} message="Are you sure you want to clone this challenge?" handleOk={modalYes} handleCancel={modalNo} />
    <h1 className='text-center'>PRODUCTS</h1>
    <div className='row justify-content-md-center'>
      <div className='col-md-12 text-center'>
        <Button variant="contained" endIcon={<AddIcon />} onClick={handleCreate}>New product</Button>
      </div>
    </div>
    <hr />
    <div className='row mt-3'>
      <div className='col-md-12'>
        <DataGrid rows={rows} columns={columnsDg}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
        />
      </div>
    </div>
  </div>
}