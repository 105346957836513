/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Slide, MenuItem, FormControl, Select, InputLabel, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';
import { forwardRef } from 'react';
import NotesIcon from '@mui/icons-material/Notes';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import GridOnIcon from '@mui/icons-material/GridOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalQuestion(props) {
    const [model, setModel] = useState([{ id: 0, question: 'question-0', type: 'type-0', questionValue: '', typeValue: '', options: [] }]);
    const formRef = useRef();
    const [questions, setQuestions] = useState([]);

    const handleSubmit = () => {

        const newState = model.map(obj => {
            let options = questions.filter(w => w.name.includes(obj.id + '-option'));
            return { ...obj, ['options']: options };
        });

        setModel(newState);
        props.setQuestions([...props.questions, ...newState]);
        handleClose();
    };

    const handleClose = () => {
        setModel([{ id: 0, question: 'question-0', type: 'type-0', questionValue: '', typeValue: '', options: [] }]);
        props.setModal({ open: false });
        setQuestions([]);
    };

    const handleChange = (event) => {

        const { name, value } = event.target;
        let index = name.split('-')[1];
        const newState = model.map(obj => {

            if (obj.id == index) {
                if (name.includes('question'))
                    return { ...obj, ['questionValue']: value };
                else
                    return { ...obj, ['typeValue']: value };
            }

            return obj;
        });

        setModel(newState);
    }

    const handleChangeOption = (event) => {
        const { name, value } = event.target;

        let question = questions.find(w => w.name === name);
        if (question === undefined)
            setQuestions(current => [...current, { 'name': name, 'value': value }]);
        else {
            const newState = questions.map(obj => {

                if (obj.name == name) {
                    return { ...obj, ['name']: name, ['value']: value };
                }
                return obj;
            });
            setQuestions(newState);
        }
    }

    const handleAddQuestion = () => {
        setModel(current => [...current, {
            question: 'question-' + model.length, type: 'type-' + model.length, 'id': model.length, questionValue: '', typeValue: ''
        }]);
    }

    return (
        <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'lg'} keepMounted onClose={handleClose}>
            <ValidatorForm ref={formRef} onSubmit={handleSubmit} onError={errors => console.log(errors)}>
                <DialogTitle className='text-center'>Add Questions</DialogTitle>
                <hr className='m-0' />
                <DialogContent>

                    {model?.map((value, index) => {
                        return (
                            <div key={index}>
                                <div className="row mt-2 justify-content-md-center">
                                    <div className="col-md-7">
                                        <TextValidator autoComplete="nope" fullWidth size="small" name={model[index].question} value={model[index].questionValue} onChange={handleChange} label={'Question Text'} validators={['required']} errorMessages={'Question Text required'} />
                                    </div>
                                    <div className="col-md-3">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="type1">Type</InputLabel>
                                            <Select labelId="type1" name={model[index].type} value={model[index].typeValue} onChange={handleChange} label={'Type'} validators={['required']} errorMessages={'Type required'}>
                                                <MenuItem value="short"><NotesIcon /> Short Answer</MenuItem>
                                                <MenuItem value="paragraph"><FormatAlignJustifyIcon /> Paragraph</MenuItem>
                                                <MenuItem value="multiple"><RadioButtonCheckedIcon />  Multiple choice</MenuItem>
                                                <MenuItem value="checkbox" disabled><CheckBoxIcon /> Checkbox</MenuItem>
                                                <MenuItem value="upload" disabled><FileUploadIcon /> Upload files</MenuItem>
                                                <MenuItem value="grid" disabled><GridOnIcon /> Grid options</MenuItem>
                                                <MenuItem value="date" disabled><CalendarTodayIcon /> Date</MenuItem>
                                                <MenuItem value="hour" disabled><ScheduleIcon /> Hour</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                {model[index].typeValue === "multiple" &&
                                    <>
                                        <div className="row justify-content-md-center mt-2">
                                            <div className='col-md-10'>
                                                <FormControl>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="female"
                                                        name="radio-buttons-group"
                                                    >
                                                        <FormControlLabel value="female" control={<><Radio /><TextField className='w-25' size='small' name={model[index].id + '-option-0'} value={questions.find(w => w.name == model[index].id + '-option-0')?.value} onChange={handleChangeOption} label="" variant="standard" /></>} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="row justify-content-md-center">
                                            <div className='col-md-10'>
                                                <FormControl>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="female"
                                                        name="radio-buttons-group"
                                                    >
                                                        <FormControlLabel value="female" control={<><Radio /><TextField className='w-25' size='small' name={model[index].id + '-option-1'} value={questions.find(w => w.name == model[index].id + '-option-1')?.value} onChange={handleChangeOption} label="" variant="standard" /></>} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="row justify-content-md-center">
                                            <div className='col-md-10'>
                                                <FormControl>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="female"
                                                        name="radio-buttons-group"
                                                    >
                                                        <FormControlLabel value="female" control={<><Radio /><TextField className='w-25' size='small' name={model[index].id + '-option-2'} value={questions.find(w => w.name == model[index].id + '-option-2')?.value} onChange={handleChangeOption} label="" variant="standard" /></>} />                                        </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        )
                    })}
                    <hr />
                    <div className='row mt-3 justify-content-md-center'>
                        <div className='col-md-12 text-center'>
                            <Button variant="contained" type="submit" onClick={handleAddQuestion}>
                                +
                            </Button>
                        </div>
                    </div>
                </DialogContent>
                <hr className='mb-0 mt-2' />
                <DialogActions className='justify-content-md-center'>
                    <Button className='btn-secondary me-1' onClick={handleClose}>Cancelar</Button>
                    <Button variant="contained" type="submit">
                        Guardar
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog >
    );
}
