/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ImageChallenge from '../../assets/trophy.png';
import { ePage } from '../../App';
import { useNavigate, Link } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import { getChallengesList, cloneChallenge, deleteChallenge } from './../../components/Challenge/challengeBase';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import TablePaginationActions from "../../components/TablePaginationActions";
import { Button, TablePagination, FormControl, IconButton, OutlinedInput, InputAdornment, InputLabel } from '@mui/material';
import { getParams } from './../../utils';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import ModalQuestionYesNo from '../../shared/ModalQuestionYesNo';

function Gamification(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [rowsGlobal, setRowsGlobal] = useState([]);
    const [modal, setModal] = useState({ open: false, title: '', message: '', extraData: {} });
    const navigate = useNavigate();

    useEffect(() => {
        getAll();
    }, []);

    const getAll = () => {
        props.setIsLoading(true);
        getChallengesList()
            .then(resp => {
                setRows(resp);
                setRowsGlobal(resp);
            })
            .catch(() => Notify.failure('Error getting data'))
            .finally(() => props.setIsLoading(false));
    }

    const handleCreate = () => {
        navigate(ePage.addChallenge);
    }

    const handleEdit = (id) => {
        navigate(`${ePage.editChallenge}?id=${id}`);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onChangeSearch = (event) => {
        const { value } = event.target;
        const found = rowsGlobal.filter(f => f.name?.toLowerCase()?.includes(value?.toLowerCase()) || f.prizeDescription?.toLowerCase()?.includes(value?.toLowerCase()));
        setPage(0);
        setRows(found);
    }

    const generateImageUrl = (picture) => {
        if (!picture) return ImageChallenge;
        return `${getParams().DATASERVICES_URL}challenge/image/${picture}`;
    }

    const modalYes = (event) => {
        props.setIsLoading(true);
        if (event.extraData.action === 'clone'){
            cloneChallenge(event.extraData.id)
                .then(() => {
                    Notify.success('Challenge cloned successfully!');
                    getAll();
                })
                .catch(() => {
                    Notify.failure('Error trying to clone challenge');
                    props.setIsLoading(false);
                })
        }

        if (event.extraData.action === 'delete') {
            deleteChallenge(event.extraData.id)
                .then(() => {
                    Notify.success('Challenge deleted successfully!');
                    getAll();
                })
                .catch(() => {
                    Notify.failure('Error trying to delete challenge');
                    props.setIsLoading(false);
                });
        }
        setModal({ open: false });

    }

    const modalNo = (event) => {
        setModal({ open: false });
    }

    const handleModalClone = (id, name) => {
        setModal({ open: true, title: 'Confirm', message: `Are you sure you want to clone this challenge: ${name}?`, extraData: { id, action:'clone' } });
    }

    const handleModalDelete = (id, name)=>{
        setModal({ open: true, title: 'Confirm', message: `Are you sure you want to delete this challenge: ${name}?`, extraData: { id, action: 'delete' } });
    }

    return (
        <div className='container'>
            <ModalQuestionYesNo modal={modal} message="Are you sure you want to clone this challenge?" handleOk={modalYes} handleCancel={modalNo} />
            <h1 className='text-center'>GAMIFICATION</h1>
            <div className='row justify-content-md-center'>
                <div className='col-md-12 text-center'>
                    <Button variant="contained" endIcon={<AddIcon />} onClick={handleCreate}>New Challenge</Button>
                </div>
            </div>
            <hr />
            <h2 className='text-center'>CHALLENGES</h2>
            <div className='row mt-3'>
                <div className='col-md-6'>
                    <FormControl fullWidth size="small">
                        <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={'text'}
                            onChange={onChangeSearch}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Search ..."
                        />
                    </FormControl>
                </div>
            </div>
            <div className='row mt-3'>
                {(rowsPerPage > 0
                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : rows
                ).map((row, inx) => (
                    <div className='col-md-4' key={inx}>
                        <div className='container-card-sm'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-8 text-center'>
                                            <h4 className='m-3 text-start'>{row.name}</h4>
                                            <p className='ms-3 mb-0 text-description text-start'>{row.prizeDescription}</p>
                                            <IconButton aria-label="delete" onClick={() => handleModalDelete(row.id, row.name)}><DeleteIcon /></IconButton>
                                            <IconButton aria-label="edit" onClick={() => handleEdit(row.id)} ><EditIcon /></IconButton>
                                            <IconButton aria-label="open" component={Link} to={"/challenge/progress/" + row.id} ><OpenInNewIcon /></IconButton>
                                            <IconButton aria-label="clone" onClick={() => handleModalClone(row.id, row.name)}><CopyAllIcon /></IconButton>
                                        </div>
                                        <div className='col-md-4'>
                                            <img className="img-fluid challenge" src={row?.picture ? `${getParams().DATASERVICES_URL}challenge/image/${row.picture}` : ImageChallenge} alt="challenge" onError={(e) => { e.target.src = ImageChallenge }} />
                                        </div>
                                    </div>
                                    <p className='stat-label-md m-0 ps-2'>Deadline {row.endDate.split(' ')[0]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='row'>
                <div className='col-12'>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </div>
            </div>
        </div>
    );
}

export default Gamification;
