/* eslint-disable no-lone-blocks */
import update from 'immutability-helper'
import { useCallback, useEffect, useState } from 'react'
import { Card } from './Card.js'
const style = {
  width: 400,
}
export const Container = (props) => {
  {
    const [cards, setCards] = useState([
      {
        avatar: "fcfc1d99712fa6a470a878e2745f061900a7a41b",
        customerChallengeId: "866a7992c52a46fe8b3b383f7604cc08",
        customerId: "f160cc3e96b04d7ab5daadcbd7a7c2f0",
        lastname: "pena",
        name: "sairio",
        nickname: null,
        points: 0,
        position: 0,
        quiz: { questions: [] },
        status: "completed",
        suscribed: true,
        id: 2,
      },
      {
        avatar: "fcfc1d99712fa6a470a878e2745f061900a7a41b",
        customerChallengeId: "866a7992c52a46fe8b3b383f7604cc08",
        customerId: "f160cc3e96b04d7ab5daadcbd7a7c2f0",
        lastname: "Vega",
        name: "Luis",
        nickname: null,
        points: 0,
        position: 0,
        quiz: { questions: [] },
        status: "completed",
        suscribed: true,
        id: 3,
      },
      {
        avatar: "fcfc1d99712fa6a470a878e2745f061900a7a41b",
        customerChallengeId: "866a7992c52a46fe8b3b383f7604cc08",
        customerId: "f160cc3e96b04d7ab5daadcbd7a7c2f0",
        lastname: "Edgar",
        name: "torres",
        nickname: null,
        points: 0,
        position: 0,
        quiz: { questions: [] },
        status: "completed",
        suscribed: true,
        id: 4,
      },
      {
        avatar: "fcfc1d99712fa6a470a878e2745f061900a7a41b",
        customerChallengeId: "866a7992c52a46fe8b3b383f7604cc08",
        customerId: "f160cc3e96b04d7ab5daadcbd7a7c2f0",
        lastname: "bastidas",
        name: "manuela",
        nickname: null,
        points: 0,
        position: 0,
        quiz: { questions: [] },
        status: "completed",
        suscribed: true,
        id: 5,
      },
      {
        avatar: "fcfc1d99712fa6a470a878e2745f061900a7a41b",
        customerChallengeId: "866a7992c52a46fe8b3b383f7604cc08",
        customerId: "f160cc3e96b04d7ab5daadcbd7a7c2f0",
        lastname: "Brito",
        name: "Alan",
        nickname: null,
        points: 0,
        position: 0,
        quiz: { questions: [] },
        status: "completed",
        suscribed: true,
        id: 6,
      },
      {
        avatar: "fcfc1d99712fa6a470a878e2745f061900a7a41b",
        customerChallengeId: "866a7992c52a46fe8b3b383f7604cc08",
        customerId: "f160cc3e96b04d7ab5daadcbd7a7c2f0",
        lastname: "Gomez",
        name: "Elber",
        nickname: null,
        points: 0,
        position: 0,
        quiz: { questions: [] },
        status: "completed",
        suscribed: true,
        id: 7,
      },
      {
        avatar: "fcfc1d99712fa6a470a878e2745f061900a7a41b",
        customerChallengeId: "866a7992c52a46fe8b3b383f7604cc08",
        customerId: "f160cc3e96b04d7ab5daadcbd7a7c2f0",
        lastname: "Meltrozo",
        name: "Rosa",
        nickname: null,
        points: 0,
        position: 0,
        quiz: { questions: [] },
        status: "completed",
        suscribed: true,
        id: 8,
      },
    ])

    useEffect(() => {
      if (props.winner) {
        const temp = [...props.winner.map((m,index)=>{
          m.id = index+1;
          return m;
        })/*, ...cards*/];
        setCards(temp);
      }
    }, [props.winner]);

    useEffect(()=>{
      if (props.setOrderWinners) {
        props.setOrderWinners(cards);
      }
    }, [cards]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
      setCards((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        }),
      )
    }, [])
    const renderCard = useCallback((card, index) => {
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          name={card.name}
          lastname={card.lastname}
          avatar={card.avatar}
          moveCard={moveCard}
        />
      )
    }, [])
    return (
      <>
        <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
      </>
    )
  }
}
