/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useRef, useEffect } from 'react';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { useNavigate, useParams } from "react-router-dom";
import { ePage } from '../../App';
import ModalImage from '../../shared/ModalImage';
import ImageChallenge from '../../assets/trophy.png';
import { save, update, getById, ImageBaseUrl } from '../../components/SmartDevices/SmartDeviceBase';


export default function SmartDeviceForm(props) {
  const formRef = useRef();
  const [model, setModel] = useState({
    id: '', name: '', description: '', photo: ''
  });
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const fileElement = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [photo, setPhoto] = useState(null);
  let { id } = useParams();


  useEffect(() => {
    console.log(id)
    if (id) {
      props.setIsLoading(true);
      getById(id)
        .then(resp => {
          if (!resp) {
            Notify.failure('Smart device doesn\'t exists', { position: 'center-bottom', });
            navigate(ePage.smartDevice)
          } else {
            setModel(resp);
          }
          if (resp.photo) {
            setPhoto(`${ImageBaseUrl}/${resp.photo}`);
          } else {
            setPhoto(ImageChallenge);
          }
        }).catch(console.log)
        .finally(() => props.setIsLoading(false));
    }
  }, []);


  const handleChange = (event) => {
    const { name, value } = event.target;
    setModel(prevState => ({
      ...prevState, [name]: value
    }));
  }

  const handleAddPicture = () => {
    fileElement.current.click();
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const totalSizeMB = file.size / Math.pow(2024, 2)
      if (totalSizeMB > 2.024) {
        Notify.failure('File is too big!', { position: 'center-bottom', });
      } else {
        let imageDataUrl = await readFile(file)
        setImageSrc(imageDataUrl);
        setModal(true);
      }
    }
  }

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  const handleSubmit = () => {
    props.setIsLoading(true);
    let request = model;
    request.photo = '';
    if (croppedImage) {
      request.photo = croppedImage.split(',')[1];
    }
    let promise = (id) ? update(request) : save(request);
    promise.then(() => {
      Notify.success('saved');
      navigate(ePage.smartDevice);
    }).catch(() => Notify.failure('Error saving data'))
      .finally(() => props.setIsLoading(false));
  };


  return <div className='container mt-5'>
    <h1 className='text-center'>Smart Device</h1>
    <ModalImage open={modal} setModalOpen={setModal} croppedImage={croppedImage} setCroppedImage={setCroppedImage} imageSrc={imageSrc} />
    <div className='row mt-3 justify-content-md-center'>
      <div className='col-md-12'>
        <ValidatorForm ref={formRef} onSubmit={handleSubmit} onError={errors => console.log(errors)}>
          <div className='container-card card-content'>
            <div className='row justify-content-md-center'>
              <div className='col-md-7'>
                <div className="row justify-content-md-center mb-4">
                  <div className='col-md-3  mt-3'>
                    {photo && !croppedImage && <img className="img-fluid challenge" src={photo} alt="product" onError={(e) => { e.target.src = ImageChallenge }} />}
                    {croppedImage && <img src={croppedImage} alt='photo' width="100" />}
                    <input type="file" style={{ display: 'none' }} max-file-size="1024" onChange={onFileChange} accept="image/*" ref={fileElement} />
                    <Button variant="contained" endIcon={<PhotoCameraIcon />} onClick={handleAddPicture}>Add</Button>
                    <input type='file' label="Picture" style={{ display: 'none' }} />
                  </div>
                  <div className="col-md-8  mt-3">
                    <div className='row'>
                      <div className='col-md-6'>
                        <TextField fullWidth size="small" name='name' onChange={handleChange} value={model.name} type='text' className="text-terms" label="Name" />
                      </div>
                      <div className='col-md-6'>
                        <FormControl size="small" fullWidth>
                          <InputLabel id="type">Type</InputLabel>
                          <Select labelId="type" required name='type' value={model.type} onChange={handleChange} label={'type'}>
                            <MenuItem value="thermostat">Thermostat</MenuItem>
                            <MenuItem value="battery">Battery</MenuItem>
                            <MenuItem value="heatpump">Heat Pump</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className='row'>
                       <div className="col-md-12 mt-3">
                         <TextField fullWidth size="small" name='description' onChange={handleChange} value={model.description} className="text-terms" multiline rows={2} label="Description" />
                       </div>
                   </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row justify-content-md-center'>
              <div className='col-md-12 text-center'>
                <Button variant="contained" type='submit'>Save</Button>
              </div>
            </div>
          </div>
        </ValidatorForm>
      </div>
    </div>
  </div>
}